import React from "react";

import { useContext } from "react";
import AuthContext from "../Store/auth-context";
import GetCountry from "./GetCountry";

const BillAddressDetails = () => {
  const authObject = useContext(AuthContext);

  return (
    <div>
      <div className="addressbox_line">
        <div className="addressbox_line_left">Straat en Nr Bus</div>
        <div className="addressbox_line_right">
          {authObject.billaddress.street} {authObject.billaddress.hnr}{" "}
          {authObject.billaddress.hnrext}
        </div>
      </div>
      <div className="addressbox_line">
        <div className="addressbox_line_left">Postcode</div>
        <div className="addressbox_line_right">
          {authObject.billaddress.zip}
        </div>
      </div>
      <div className="addressbox_line">
        <div className="addressbox_line_left">Stad</div>
        <div className="addressbox_line_left">
          {authObject.billaddress.city}
        </div>
      </div>
      <div className="addressbox_line">
        <div className="addressbox_line_left">Land</div>
        <div className="addressbox_line_left">
          <GetCountry code={authObject.billaddress.country} />
        </div>
      </div>
    </div>
  );
};

export default BillAddressDetails;
