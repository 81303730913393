import React, { useState, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { titles } from "../Data/data";
import useLayout from "../Functions/useLayout";
import AuthContext from "../Store/auth-context";

const Signup = ({ signup, isAuthenticated }) => {
  const [accountCreated, setAccountCreated] = useState(false);
  const [clickedGC, setClickedGC] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    title: "",
    firstname: "",
    name: "",
    password: "",
    re_password: "",
    is_active: false,
    consent: true,
    subscribed: true,
  });
  const [passwordType, setPasswordType] = useState("password");
  const bg = useLayout(2, 0);
  const color = useLayout(2, 1);

  const authObject = useContext(AuthContext);
  const navigate = useNavigate();

  const {
    email,
    phone,
    title,
    firstname,
    name,
    password,
    re_password,
    consent,
    subscribed,
  } = formData;

  const handleChangeUser = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChecker = (e) => {
    console.log(e.target.checked);
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };

  const pwToggler = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleCreateUser = (e) => {
    e.preventDefault();
    var emaillower = email.toLowerCase();
    if (password === re_password) {
      authObject.signup(
        emaillower,
        phone,
        title,
        firstname,
        name,
        password,
        re_password,
        consent,
        subscribed
      );
      authObject.setactivating(true);
      setAccountCreated(true);
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (accountCreated) {
    return <Navigate to="/loginaftersignup/" />;
  }

  return (
    <div className="py-2" style={{ backgroundColor: bg, color: color }}>
      <div className="container mt-5">
        <h1>Registreer</h1>
        <p>
          U krijgt na het invullen van onderstaand formulier een email
          toegestuurd met een knop om uw account te activeren.
        </p>
        {!accountCreated && (
          <>
            <p>Maak hier je account aan</p>
            <form onSubmit={(e) => handleCreateUser(e)}>
              <label>Email</label>
              <div className="form-group">
                <input
                  className="form-control"
                  type="email"
                  placeholder="Email*"
                  name="email"
                  value={email}
                  onInput={(e) => handleChangeUser(e)}
                  required
                />
              </div>
              <label>Telefoon</label>
              <div className="form-group">
                <input
                  className="form-control"
                  type="phone"
                  placeholder="+32 xx xxxxxx"
                  name="phone"
                  value={phone}
                  onInput={(e) => handleChangeUser(e)}
                />
              </div>
              <div className="form-group mt-3">
                <label className="me-3">Aanspreking</label>
                <select
                  className="form-control w-25"
                  placeholder="Aanspreking"
                  type="text"
                  required
                  id="title"
                  name="title"
                  value={title}
                  onInput={(e) => handleChangeUser(e)}
                >
                  <option name="title" value="">
                    - titel -
                  </option>
                  {titles &&
                    titles.map((tit, index) => {
                      return (
                        <option name="title" value={tit.code} key={index}>
                          {tit.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="form-group mt-3">
                <label>Voornaam</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Voornaam*"
                  name="firstname"
                  value={firstname}
                  default="Dominique"
                  onInput={(e) => handleChangeUser(e)}
                  pattern="[a-zA-Z][a-zA-Zéèçà-/s]{1,255}$"
                  required
                />
              </div>
              <div className="form-group mt-3">
                <label>Achternaam</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Naam*"
                  name="name"
                  value={name}
                  default="Rongé"
                  onInput={(e) => handleChangeUser(e)}
                  pattern="[a-zA-Z][a-zA-Zéèçà-/s]{1,255}$"
                  maxLength="255"
                  required
                />
              </div>
              <div className="form-group mt-3">
                <label>
                  Paswoord (min 8 characters, hoofd-, kleine letter, cijfer en
                  speciaal character (bvb -/_\)
                </label>
                <div className="form-control d-flex justify-content-between">
                  <input
                    className="bg-transparent border-0 w-75"
                    type={passwordType}
                    placeholder="Paswoord*"
                    name="password"
                    value={password}
                    default="Obtanium_007"
                    onInput={(e) => handleChangeUser(e)}
                    pattern="(?=^.{8,255}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                    required
                  />
                  <button
                    type="button"
                    className="bg-transparent border-0"
                    onClick={() => pwToggler()}
                  >
                    {passwordType === "password" ? (
                      <i className="bi bi-eye fs-5"></i>
                    ) : (
                      <i className="bi bi-eye-slash fs-5"></i>
                    )}
                  </button>
                </div>
              </div>
              <div className="form-group mt-3">
                <label>Nogmaals je paswoord</label>
                <div className="form-control d-flex justify-content-between">
                  <input
                    className="bg-transparent border-0 w-75"
                    type={passwordType}
                    placeholder="Nogmaals paswoord*"
                    name="re_password"
                    value={re_password}
                    default="Obtanium_007"
                    onInput={(e) => handleChangeUser(e)}
                    pattern={password}
                    required
                  />
                  <button
                    type="button"
                    className="bg-transparent border-0"
                    onClick={() => pwToggler()}
                  >
                    {passwordType === "password" ? (
                      <i className="bi bi-eye fs-5"></i>
                    ) : (
                      <i className="bi bi-eye-slash fs-5"></i>
                    )}
                  </button>
                </div>
              </div>
              <div className="form-check mt-3 mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="subscribed"
                  name="subscribed"
                  checked={subscribed}
                  onChange={(e) => handleChecker(e)}
                />
                <label htmlFor="subscribed" className="form-check-label">
                  Ik wens op de hoogte te blijven via de nieuwsbrief
                </label>
              </div>
              Door te registreren accepteer je de{" "}
              <a className="" onClick={() => setClickedGC(!clickedGC)}>
                Algemene voorwaarden
              </a>
              {clickedGC &&
                authObject.organisation &&
                authObject.organisation.generalconditions && (
                  <>
                    <p>{authObject.organisation.generalconditions}</p>
                  </>
                )}
              <div className="form-group mt-3">
                <button className="btn btn-primary" type="submit">
                  Registreer
                </button>
                <button
                  className="btn btn-secondary ms-5"
                  onClick={() => navigate("/")}
                >
                  Annuleer
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default Signup;
