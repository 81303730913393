import React, { useState, useContext, useEffect } from "react";
import ShopContext from "../Store/shop-context";
import ProductList from "./ProductList";
import useLayout from "../Functions/useLayout";

const Promotions = ({ productList, productOptions }) => {
  const shopObject = useContext(ShopContext);
  const color = useLayout(4, 1);
  const bg = useLayout(4, 0);

  useEffect(() => {
    shopObject.getpromoproducts();
    shopObject.getpromoproductoptions();
  }, []);

  return (
    <>
      {productList.length > 0 && shopObject.productsettings && (
        <>
          <div className="promogroup">
            <div className="promotitle fs-1">In de kijker</div>
            {productList && productOptions && (
              <ProductList
                productList={productList}
                productOptions={productOptions}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Promotions;
