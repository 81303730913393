import React, { useState, Fragment, useContext, useEffect } from "react";

import AuthContext from "../Store/auth-context";
import ShopContext from "../Store/shop-context";

import { useNavigate } from "react-router-dom";
import ShowUserBox from "../Components/ShowUserBox";

import GetTitle from "../Components/GetTitle";
import GetCountry from "../Components/GetCountry";
import DisplayDatefromPython from "../Components/DisplayDatefromPython";
import getLayout from "../Functions/getLayout";

const OrderConfirmPage = () => {
  const authObject = useContext(AuthContext);
  const shopObject = useContext(ShopContext);
  const [remarks, setRemarks] = useState("");
  const [layout, setLayout] = useState();

  let cart = shopObject.cart;
  let cartoptions = shopObject.cartoptions;

  let navigate = useNavigate();

  var totalprice = 0;
  var totalvat = 0;

  cart.map((c) => {
    let ctotal =
      (c.cartitemprice * (1 + c.cartitemvat)).toFixed(2) * c.quantity;
    let ctotalwovat = c.cartitemprice.toFixed(2) * c.quantity;
    totalprice = totalprice + ctotal;
    totalvat = totalvat + (ctotal - ctotalwovat);
  });

  useEffect(() => {
    if (shopObject.shopoptions.layout) {
      setLayout(JSON.parse(shopObject.shopoptions.layout));
    }
  }, [shopObject.shopoptions.layout]);

  function confirm() {
    shopObject.ordercreate(authObject.shopsessiontoken, remarks);
  }

  if (!authObject.accesstoken) {
    navigate("/");
  }

  function changeRemarks(e) {
    const value = e.target.value;
    setRemarks(value);
  }

  useEffect(() => {
    shopObject.loadcart();
    document
      .getElementById("orderconfirmpage")
      .scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div
      className="confirmpage"
      style={{
        backgroundColor: getLayout(layout, 10, 0),
        color: getLayout(layout, 10, 1),
      }}
      id="orderconfirmpage"
    >
      <div className="h1 confirmtitle">Check uw Bestelling</div>
      {/* ---------- Buttons ------------- */}
      <div className="checkoutbuttongroup">
        <button
          className="btn btn-secondary gobackbutton"
          onClick={(e) => navigate("/checkout/")}
        >
          {" "}
          Terug
        </button>
        <button
          className="btn btn-primary proceedbutton"
          onClick={(e) => confirm()}
        >
          Ik bevestig en bestel
        </button>
      </div>

      {/* ---------- Ordered items ------------- */}
      <div className="confirmitembox">
        <div className="h3 confirmtitle">Bestelling</div>
        <table
          className="table"
          style={{
            backgroundColor: getLayout(layout, 10, 0),
            color: getLayout(layout, 10, 1),
          }}
        >
          <thead>
            <tr>
              <th>Product</th>
              <th>Prijs</th>
              <th>Aantal</th>
              <th>Totaal</th>
            </tr>
          </thead>
          <tbody>
            {cart.map((cart, index) => {
              return (
                <Fragment key={index}>
                  <tr>
                    <td>{cart.name}</td>
                    <td>
                      {(cart.cartitemprice * (1 + cart.cartitemvat)).toFixed(2)}
                    </td>
                    <td>
                      {cart.quantity}
                      {cart.varianttype === "cuttoweight" ? (
                        <>x{cart.weight}g</>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      {(
                        (cart.cartitemprice * (1 + cart.cartitemvat)).toFixed(
                          2
                        ) * cart.quantity
                      ).toFixed(2)}
                    </td>
                  </tr>
                  {cart.options && cart.options.length > 0 && (
                    <tr>
                      <td></td>
                      <td>keuzeopties</td>
                      <td>
                        {cart.options.map((co, index) => {
                          return (
                            <Fragment key={index}>
                              {cartoptions.filter(
                                (option) => option.id === co
                              )[0] && (
                                <>
                                  {index > 0 && <>{", "}</>}
                                  {
                                    cartoptions.filter(
                                      (option) => option.id === co
                                    )[0].description
                                  }
                                </>
                              )}
                            </Fragment>
                          );
                        })}
                      </td>
                      <td></td>
                    </tr>
                  )}
                  <tr></tr>
                </Fragment>
              );
            })}
            <tr>
              <td>
                <strong>Totaalprijs met BTW</strong>
              </td>
              <td>
                <strong>{totalprice.toFixed(2)}</strong>
              </td>
            </tr>
            <tr>
              <td>BTW </td>
              <td>{totalvat.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>

        <label>
          Voeg hier eventuele opmerkingen toe ({remarks.length}/500)
        </label>
        <textarea
          type="textarea"
          className="w-100 mb-3 bg-white text-black"
          value={remarks}
          onChange={changeRemarks}
          pattern="[a-zA-Z0-9éèçà\-\s^<>]{1,500}$"
        />
      </div>

      {/* ---------- User data ------------- */}
      <ShowUserBox />

      {/* ---------- Shipping data ------------- */}
      {/* ---------- Mail shipment ------------- */}
      {shopObject.shipmenttype === "M" && (
        <div className="addressbox mt-3">
          <div className="h3 text-center">Verzendadres</div>
          <div className="row ms-3">
            <div className="col-6">Naam</div>
            <div className="col-6">
              <GetTitle code={authObject.shipaddress.title} />{" "}
              {authObject.shipaddress.firstname} {authObject.shipaddress.name}
            </div>
          </div>
          <div className="row ms-3">
            <div className="col-6">Adres</div>
            <div className="col-6">
              {authObject.shipaddress.street} {authObject.shipaddress.hnr}{" "}
              {authObject.shipaddress.hnrext}
            </div>
          </div>
          <div className="row ms-3">
            <div className="col-6">Stad</div>
            <div className="col-6">
              {authObject.shipaddress.zip} {authObject.shipaddress.city}
            </div>
          </div>
          <div className="row ms-3">
            <div className="col-6">Land</div>
            <div className="col-6">
              <GetCountry code={authObject.shipaddress.country} />
            </div>
          </div>

          <div className="row ms-3">
            <div className="col-6">Geplande levering </div>
            <div className="col-6">
              <DisplayDatefromPython date={shopObject.deldate} />
            </div>
          </div>
        </div>
      )}
      {/* ---------- POS shipment ------------- */}
      {shopObject.shipmenttype === "P" && (
        <div className="addressbox mt-3 py-3">
          <div className="h3 text-center">Ophaal adres</div>
          <div className="row ms-3">
            <div className="col-6">Naam</div>
            <div className="col-6">
              <GetTitle code={authObject.shipaddress.title} />{" "}
              {authObject.shipaddress.firstname} {authObject.shipaddress.name}
            </div>
          </div>
          <div className="row ms-3">
            <div className="col-6">Adres</div>
            <div className="col-6">
              {authObject.shipaddress.street} {authObject.shipaddress.hnr}{" "}
              {authObject.shipaddress.hnrext}
            </div>
          </div>
          <div className="row ms-3">
            <div className="col-6">Stad</div>
            <div className="col-6">
              {authObject.shipaddress.zip} {authObject.shipaddress.city}
            </div>
          </div>
          <div className="row ms-3">
            <div className="col-6">Land</div>
            <div className="col-6">
              <GetCountry code={authObject.shipaddress.country} />
            </div>
          </div>
          <div className="row ms-3 fw-bold">
            <div className="col-6">Datum afhaling</div>
            <div className="col-6">
              <DisplayDatefromPython date={shopObject.booking.cd} />
            </div>
          </div>
          <div className="row ms-3 fw-bold">
            <div className="col-6">Tijdslot</div>
            <div className="col-6">
              {shopObject.booking.ts.slotfrom.slice(0, 5)}-
              {shopObject.booking.ts.slotto.slice(0, 5)}
            </div>
          </div>
        </div>
      )}

      {/* ---------- Invoice data ------------- */}
      <div className="addressbox my-3 py-3">
        <div className="h3 text-center">Facturatie adres</div>
        <div className="row ms-3">
          <div className="col-6">Naam</div>
          <div className="col-6">
            <GetTitle code={authObject.billaddress.title} />{" "}
            {authObject.billaddress.firstname} {authObject.billaddress.name}
          </div>
        </div>
        <div className="row ms-3">
          <div className="col-6">Adres</div>
          <div className="col-6">
            {authObject.billaddress.street} {authObject.billaddress.hnr}{" "}
            {authObject.billaddress.hnrext}{" "}
          </div>
        </div>
        <div className="row ms-3">
          <div className="col-6">Stad</div>
          <div className="col-6">
            {authObject.billaddress.zip} {authObject.billaddress.city}
          </div>
        </div>
        <div className="row ms-3">
          <div className="col-6">Land</div>
          <div className="col-6">
            <GetCountry code={authObject.billaddress.country} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmPage;
