import React, { useContext, Fragment } from "react";
import AuthContext from "../Store/auth-context";
import ShopContext from "../Store/shop-context";
import { useNavigate } from "react-router-dom";

import CartItemLine from "../Components/CartItemLine";
import CartItemLineCTW from "../Components/CartItemLineCTW";
import CartTotal from "../Components/CartTotal";

const Cart = () => {
  const authObject = useContext(AuthContext);
  const shopObject = useContext(ShopContext);
  const navigate = useNavigate();

  function proceedHandler() {
    shopObject.setshowcart(false);
    shopObject.setcheckout(true);
    authObject.setshipaddress(
      authObject.addresslist.filter(
        (address) => address.defaultshipping === true
      )[0]
    );
    authObject.setbilladdress(
      authObject.addresslist.filter(
        (address) => address.defaultbilling === true
      )[0]
    );
    navigate("/checkout/");
    return;
  }

  function deleteCart() {
    authObject.createshopsession();
    shopObject.setshowcart(!shopObject.showcart);
  }

  function loginHandler() {
    shopObject.setshowcart(false);
    navigate("/login/");
  }

  return (
    <>
      {shopObject.showcart && (
        <div className="cartwindow">
          <div className="d-flex justify-content-between">
            <div className="">
              <button
                className="btn btn-secondary cartbutton"
                onClick={(e) => deleteCart()}
              >
                Leegmaken
              </button>
            </div>
            <div className="fs-4">Winkelmandje</div>
            <div className="">
              <button
                className="btn btn-secondary"
                onClick={(e) => shopObject.setshowcart(false)}
              >
                Sluit
              </button>
            </div>
          </div>
          <table className="">
            <thead>
              <tr className="fs-6">
                <th scope="col-2">Beschrijving</th>
                <th scope="col-2">Eenheidsprijs</th>
                <th scope="col-2">Aantal</th>
                <th scope="col-2">Totaal</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="fs-6 overflow-y-scroll">
              {shopObject.cart.map((cartitem, index) => {
                switch (cartitem.varianttype) {
                  case "standard":
                    return (
                      <Fragment key={index}>
                        <CartItemLine
                          cartitem={cartitem}
                          reset={shopObject.shopreset}
                          buttons={true}
                        />
                      </Fragment>
                    );
                    break;
                  case "cuttoweight":
                    return (
                      <Fragment key={index}>
                        <CartItemLineCTW
                          cartitem={cartitem}
                          reset={shopObject.shopreset}
                          buttons={true}
                        />
                      </Fragment>
                    );
                    break;
                }
              })}
            </tbody>
          </table>
          <CartTotal />
          <div className="cartconfirmbuttongroup">
            <button
              className="btn btn-secondary mt-3 w-40"
              onClick={(e) => shopObject.setshowcart(false)}
            >
              Verderwinkelen
            </button>
            {!authObject.user && (
              <button
                className="btn btn-primary mt-3 w-40"
                onClick={() => loginHandler()}
              >
                Je dient in te loggen
              </button>
            )}
            {authObject.user && shopObject.cart.length > 0 && (
              <button
                className="btn btn-success mt-3 w-40"
                onClick={(e) => proceedHandler()}
              >
                Ga naar uitchecken
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Cart;
