import React from "react";
import OrderStandardCounter from "./OrderStandardCounter";
import ToggleOptions from "./ToggleOptions";
import OrderOptions from "./OrderOptions";

const PCStandardBody = ({
  product,
  productoptions,
  selectingOptions,
  setSelectingOptions,
}) => {
  return (
    <div>
      <h4 className="card-title">{product.name}</h4>
      {!selectingOptions && (
        <>
          <p className="card-description">{product.description}</p>
          <p className="card-text">
            Prijs{" "}
            <strong>
              {(product.price * (1 + product.vat)).toFixed(2)} €{product.per}
            </strong>
          </p>
          <div>
            {productoptions.length === 0 && (
              <>
                <OrderStandardCounter product={product} />
              </>
            )}
          </div>
          {productoptions.length > 0 && (
            <>
              <ToggleOptions
                product={product}
                setToggler={setSelectingOptions}
              />
            </>
          )}
        </>
      )}

      {selectingOptions && productoptions && (
        <div>
          <OrderOptions
            product={product}
            productoptions={productoptions}
            setToggler={setSelectingOptions}
          />
        </div>
      )}
    </div>
  );
};

export default PCStandardBody;
