import React, { useContext } from "react";
import ShopContext from "../Store/shop-context";

const useLayout = (index1, index2) => {
  const shopObject = useContext(ShopContext);
  let style = "";

  if (shopObject.shopoptions.layout) {
    const layout = JSON.parse(shopObject.shopoptions.layout);
    style = layout[index1]["colors"][index2].rgba;
  }

  return style;
};

export default useLayout;
