import React, { useState, useEffect } from "react";
import "../index.css";
import ProductDetail from "../Components/ProductDetail";
import ProductCard from "../Components/ProductCard";

const ProductList = ({ productList, productOptions }) => {
  const [productDetail, setProductDetail] = useState(false);
  const [scrollItem, setScrollItem] = useState(0);

  const clickDetail = (productid) => {
    setProductDetail(productid);
  };

  useEffect(() => {
    if (scrollItem !== 0) {
      document
        .getElementById(scrollItem)
        .scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollItem]);

  return (
    <div>
      {productDetail && productList && productOptions && (
        <ProductDetail
          prod={productDetail}
          setProd={setProductDetail}
          productoptions={
            productOptions.filter((po) => po.product === productDetail).length >
            0
              ? productOptions.filter((po) => po.product === productDetail)
              : []
          }
          setScrollItem={setScrollItem}
        />
      )}

      {!productDetail && productList && productOptions && (
        <>
          <div className="cardgroup">
            {productList.map((product, index) => {
              return (
                <div key={index}>
                  <ProductCard
                    product={product}
                    productoptions={
                      productOptions.filter((po) => po.product === product.id)
                        .length > 0
                        ? productOptions.filter(
                            (po) => po.product === product.id
                          )
                        : []
                    }
                    clickDetail={clickDetail}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ProductList;
