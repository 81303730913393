import React, { Fragment, useState, useContext } from "react";
import AuthContext from "../Store/auth-context";
import timetoSeconds from "../Functions/timetoSeconds";
import { useEffect } from "react";

const SelectSlot = ({
  tsarray,
  av,
  loc,
  selectSlot,
  clickDate,
  timePusher,
  datePusher,
}) => {
  const authObject = useContext(AuthContext);
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    getSlotBookings(clickDate, loc);
  }, [clickDate]);

  const getSlotBookings = async (clickDate, loc) => {
    var url = process.env.REACT_APP_API_URL + "api/bookings/get/";
    let resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        webshop: authObject.webshoptoken,
        date: new Date(clickDate),
        location: loc.name,
      }),
    });
    if (resp.status === 200) {
      let data = await resp.json();
      setBookings(data);
    } else {
      console.log("no bookings or something wrong");
    }
  };

  let cd = new Date(clickDate);
  return (
    <>
      {tsarray
        .filter((ts) => ts.availability === av.id)
        .map((ts, index3) => {
          if (bookings.length > 0 && ts.maxbookings) {
            let presentbookings = bookings.filter(
              (bk) => bk.slotfrom === ts.slotfrom
            ).length;
            if (presentbookings >= ts.maxbookings) {
              return (
                <Fragment key={index3}>
                  <div className="ccotimeline">
                    <div className="ccotimeslot">
                      {ts.slotfrom.slice(0, 5)}
                      {" - "}
                      {ts.slotto.slice(0, 5)}
                    </div>
                    <button className="btn btn-secondary btn-sm ccotimeslotbutton">
                      Slot volzet
                    </button>
                  </div>
                </Fragment>
              );
            }
          }
          return (
            <Fragment key={index3}>
              {timePusher &&
                cd.getTime() === new Date(datePusher).getTime() &&
                timetoSeconds(ts.slotfrom) >= timetoSeconds(timePusher) && (
                  <>
                    <div key={index3} className="ccotimeline">
                      <div className="ccotimeslot">
                        {ts.slotfrom.slice(0, 5)}
                        {" - "}
                        {ts.slotto.slice(0, 5)}
                      </div>
                      <button
                        className="btn btn-primary btn-sm ccotimeslotbutton"
                        onClick={(e) => selectSlot(clickDate, ts, loc, av)}
                      >
                        Kies
                      </button>
                    </div>
                  </>
                )}
              {timePusher &&
                cd.getTime() === new Date(datePusher).getTime() &&
                timetoSeconds(ts.slotfrom) < timetoSeconds(timePusher) && (
                  <>
                    <div key={index3} className="ccotimeline">
                      <div className="ccotimeslot">
                        {ts.slotfrom.slice(0, 5)}
                        {" - "}
                        {ts.slotto.slice(0, 5)}
                      </div>
                      <button className="btn btn-secondary btn-sm ccotimeslotbutton">
                        Niet meer beschikbaar
                      </button>
                    </div>
                  </>
                )}
              {new Date(clickDate) > datePusher && (
                <>
                  <div key={index3} className="ccotimeline">
                    <div className="ccotimeslot">
                      {ts.slotfrom.slice(0, 5)}
                      {" - "}
                      {ts.slotto.slice(0, 5)}
                    </div>
                    <button
                      className="btn btn-primary tn-sm ccotimeslotbutton"
                      onClick={(e) => selectSlot(clickDate, ts, loc, av)}
                    >
                      Kies
                    </button>
                  </div>
                </>
              )}
            </Fragment>
          );
        })}
    </>
  );
};

export default SelectSlot;
