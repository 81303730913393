import React, { useState, useEffect, useContext } from "react";
import ShopContext from "../Store/shop-context";
import AuthContext from "../Store/auth-context";
import ShowProductSetting from "./ShowProductSetting";
import ClickPop from "./ClickPop";

import roundOff from "../Functions/roundOff";

const OrderCTWCounter = ({
  product,
  productoptions,
  item,
  setItem,
  setToggler,
}) => {
  const shopObject = useContext(ShopContext);
  const authObject = useContext(AuthContext);
  var [pop, setPop] = useState(false);

  const [counter, setCounter] = useState(product.startquantity);

  useEffect(() => {
    setCounter(product.startquantity);
  }, [product]);

  function handlePlus() {
    setCounter(counter + product.incrementalquantity);
  }

  function handleMin() {
    counter > product.incrementalquantity
      ? setCounter(counter - product.incrementalquantity)
      : setCounter(product.incrementalquantity);
  }

  const onChange = (e) => {
    e.target.value >= 0 ? setCounter(parseInt(e.target.value)) : setCounter(0);
  };

  // --- productoptions exist --> adding item to cart ---
  const ReturnItem = (it) => {
    if (counter === 0) {
      return;
    }
    it.weight = counter;
    it.quantity = 1;
    it.cartitemprice = (product.price * item.weight) / 1000;
    it.cartitemtotal = item.cartitemprice * item.quantity;
    it.shopsessiontoken = authObject.shopsessiontoken;
    it.webshop = authObject.webshoptoken;
    it.varianttype = product.varianttype;
    item = it;
    setItem(it);
    setCounter(product.startquantity);
    setToggler(true);
  };

  // --- no productoptions exist --> adding item to cart ---
  const AddToCart = (it) => {
    if (counter === 0) {
      return;
    }
    it.weight = counter;
    it.quantity = 1;
    it.cartitemprice = (product.price * item.weight) / 1000;
    it.cartitemtotal = item.cartitemprice * item.quantity;
    it.shopsessiontoken = authObject.shopsessiontoken;
    it.webshop = authObject.webshoptoken;
    it.varianttype = product.varianttype;
    shopObject.cartitemcreate(it);
    setCounter(product.startquantity);
    if (setToggler) {
      setToggler(false);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-4">
          <button
            className="ctwinput m-0 p-0 text-center border-0 bg-success text-white"
            onClick={handlePlus}
          >
            +{product.incrementalquantity}
          </button>
          <div className="d-inline-flex">
            <input
              className="ctwinput text-center fw-bold"
              type="number"
              min="0"
              value={counter}
              onChange={onChange}
              aria-label={`gewicht voor ${product.name}`}
            />
          </div>
          <button
            className="ctwinput m-0 p-0 text-center border-0 bg-success text-white"
            onClick={handleMin}
          >
            -{product.incrementalquantity}
          </button>
        </div>

        <div className="col-8 d-flex align-items-center">
          gram
          <button
            className="col btn btn-primary ms-sm-1 mt-2"
            onClick={(e) => {
              pop = true;
              setPop(true);
              if (productoptions.length > 0) {
                ReturnItem(item);
              } else {
                AddToCart(item);
              }
            }}
          >
            {pop && <ClickPop pop={pop} setPop={setPop} />}
            <div>bestel</div>
            <div>
              {(product.price * (counter / 1000) * (1 + product.vat)).toFixed(
                2
              )}
              €
            </div>
          </button>
        </div>
      </div>

      <div className="restrictions text-end">
        {product &&
          product.settings.length > 0 &&
          shopObject.productsettings.length > 0 && (
            <>
              <div>
                Opgelet !
                <div className="restrictiontext">
                  <ShowProductSetting
                    prodsettings={product.settings}
                    allsettings={shopObject.productsettings}
                  />
                </div>
              </div>
            </>
          )}
      </div>
    </>
  );
};

export default OrderCTWCounter;
