import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../Store/auth-context";
import useLayout from "../Functions/useLayout";

function LoginPage() {
  let { loginuser, errormessage, seterrormessage } = useContext(AuthContext);

  const [formData, setFormData] = useState({ email: "", password: "" });
  const [passwordType, setPasswordType] = useState("password");
  const { email, password } = formData;
  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById("loginpage").scrollIntoView({ behavior: "smooth" });
  }, []);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    seterrormessage("");
    var lowermail = email.toLowerCase();
    loginuser(lowermail, password);
  };

  const pwToggler = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div
      className="container mt-5"
      style={{ backgroundColor: useLayout(2, 0), color: useLayout(2, 1) }}
      id="loginpage"
    >
      <h1>Log in</h1>
      <p>Log in op je account</p>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <input
            className="form-control"
            type="email"
            placeholder="email"
            name="email"
            value={email}
            onChange={(e) => onChange(e)}
            required
          />
        </div>
        <div className="form-group my-3">
          <div className="form-control d-flex justify-content-between">
            <input
              className="bg-transparent border-0 w-75"
              type={passwordType}
              placeholder="password"
              name="password"
              value={password}
              onChange={(e) => onChange(e)}
              required
            />
            <button
              type="button"
              className="bg-transparent border-0"
              onClick={() => pwToggler()}
            >
              {passwordType === "password" ? (
                <i className="bi bi-eye fs-5"></i>
              ) : (
                <i className="bi bi-eye-slash fs-5"></i>
              )}
            </button>
          </div>
        </div>
        <div className="text-danger">{errormessage}</div>
        <div className="form-group mt-3">
          <button className="btn btn-primary w-50" type="submit">
            Log in
          </button>
          <button
            className="btn btn-secondary ms-3"
            onClick={() => navigate("/")}
          >
            Annuleer
          </button>
          <div className="mt-3">
            <span className="">Ben je je paswoord vergeten ? </span>
            <Link to="/reset-password/">
              <button className="btn btn-primary btn-sm">
                Herstel Paswoord
              </button>
            </Link>
          </div>
        </div>
      </form>
      <h1 className="mt-4">Registreer</h1>
      <div className="mt-2">
        <div>
          <span className="me-2">Heb je nog geen account ?</span>
        </div>
        <Link to="/Signup/">
          <button className="btn btn-primary w-50 my-2">Registreer hier</button>
        </Link>
      </div>
    </div>
  );
}

export default LoginPage;
