import React, { useEffect, useState, useContext, useRef } from "react";

import ShopContext from "../Store/shop-context";
import AuthContext from "../Store/auth-context";

import Promotions from "../Segments/Promotions";
import ProductList from "../Segments/ProductList";
import Catbuttons from "../Components/Catbuttons";
import AddressInit from "../Components/AddressInit";
import Spinner from "../Components/Spinner";
import useLayout from "../Functions/useLayout";
import getLayout from "../Functions/getLayout";
import CartFollower from "../Components/CartFollower";

const Home = () => {
  const shopObject = useContext(ShopContext);
  const authObject = useContext(AuthContext);
  const [checkPromo, setCheckPromo] = useState(false);
  const [prodList, setProdList] = useState(shopObject.products);
  const [layout, setLayout] = useState();

  useEffect(() => {
    shopObject.setspinner(true);
    shopObject.getpromoproducts();
    shopObject.getpromoproductoptions();
    shopObject.setcheckout(false);
    shopObject.setworkcat(null);
    shopObject.setworksubcat(null);
  }, []);

  useEffect(() => {
    if (shopObject.shopoptions.layout) {
      setLayout(JSON.parse(shopObject.shopoptions.layout));
    }
  }, [shopObject.shopoptions.layout]);

  useEffect(() => {
    setProdList(shopObject.products);
  }, [shopObject.products]);

  // run this when nopromoproducts and finally categories are loaded
  useEffect(() => {
    if (shopObject.nopromoproducts === true && shopObject.category.length > 0) {
      shopObject.setworkcat(shopObject.category[0]);
    }
  }, [shopObject.nopromoproducts, shopObject.category, checkPromo]);

  useEffect(() => {
    shopObject.setspinner(true);
    if (shopObject.workcat !== null) {
      if (shopObject.workcat !== "fav") {
        shopObject.getcatproducts(shopObject.workcat);
        shopObject.getcatproductoptions(shopObject.workcat);
      } else {
        shopObject.getfavproducts(authObject.user.id);
        shopObject.getfavproductoptions(authObject.user.id);
      }
    } else {
      setCheckPromo(!checkPromo);
      shopObject.getpromoproducts();
      shopObject.getpromoproductoptions();
    }
  }, [shopObject.workcat]);

  useEffect(() => {
    if (shopObject.worksubcat !== null) {
      setProdList(
        shopObject.products.filter(
          (product) => product.subcategory === shopObject.worksubcat.id
        )
      );
    } else {
      setProdList(shopObject.products);
    }
  }, [shopObject.worksubcat]);

  return (
    <div
      className="homepage"
      style={{
        backgroundColor: useLayout(0, 0),
        color: useLayout(0, 1),
      }}
    >
      {((shopObject.products && !authObject.user) ||
        (authObject.user && authObject.addresslist.length > 0)) && (
        <>
          <div
            style={{
              backgroundColor: getLayout(layout, 3, 0),
              color: getLayout(layout, 3, 1),
            }}
          >
            <Catbuttons
              cat={shopObject.workcat}
              setCat={shopObject.setworkcat}
              subCat={shopObject.worksubcat}
              setSubCat={shopObject.setworksubcat}
              catList={shopObject.category}
              subCatList={shopObject.subcategory}
              favorites={authObject.favorites}
              user={authObject.user}
            />
          </div>
          {shopObject.spinner && (
            <>
              <Spinner />
            </>
          )}
          {!shopObject.spinner && (
            <>
              <CartFollower />
              {shopObject.workcat === null &&
                shopObject.nopromoproducts === false && (
                  <div
                    style={{
                      backgroundColor: getLayout(layout, 4, 0),
                      color: getLayout(layout, 4, 1),
                    }}
                  >
                    <Promotions
                      productList={shopObject.products}
                      productOptions={shopObject.productoptions}
                    />
                  </div>
                )}

              {(shopObject.workcat !== null ||
                shopObject.nopromoproducts === true) && (
                <div
                  style={{
                    backgroundColor: getLayout(layout, 5, 0),
                    color: getLayout(layout, 5, 1),
                  }}
                >
                  <ProductList
                    productList={prodList}
                    productOptions={shopObject.productoptions}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}

      {authObject.user && authObject.addresslist.length === 0 && (
        <div
          className="py-2"
          style={{
            backgroundColor: getLayout(layout, 2, 0),
            color: getLayout(layout, 2, 1),
          }}
        >
          <AddressInit />
        </div>
      )}
    </div>
  );
};

export default Home;
