import React, { useContext, useState } from "react";
import AuthContext from "../Store/auth-context";
import { useNavigate } from "react-router-dom";

import { isocountries, titles } from "../Data/data";
import GetCountry from "./GetCountry";
import GetTitle from "./GetTitle";

const AddressBox = ({
  add,
  editdel,
  create,
  createToggler,
  setCreateToggler,
}) => {
  const authObject = useContext(AuthContext);
  const navigate = useNavigate();

  if (!authObject.user) {
    navigate("/");
  }

  const userid = authObject.user.id;
  const [ad, setAd] = useState(add);
  const [editToggler, setEditToggler] = useState(false);
  const [delToggler, setDelToggler] = useState(false);

  const createAddress = async (data) => {
    var url = `${process.env.REACT_APP_API_URL}api/address/create/`;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + localStorage.getItem("access"),
      },
      body: JSON.stringify(data),
    })
      .then((resp) => {
        if (resp.ok) {
          console.log("address create ok");
          authObject.getaddresslist(authObject.user.id);
          if (setCreateToggler) {
            setCreateToggler(false);
          }
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const updateAddress = async (address) => {
    console.log("address to update :", address);
    var url = `${process.env.REACT_APP_API_URL}api/address/update/${address.id}/`;
    await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + localStorage.getItem("access"),
      },
      body: JSON.stringify(address),
    })
      .then((resp) => {
        if (resp.ok) {
          console.log("addressupdateresponse ok");
          authObject.getaddresslist(authObject.user.id);
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const deleteAddress = async (address) => {
    var url = `${process.env.REACT_APP_API_URL}api/address/delete/${address.id}/`;
    await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + localStorage.getItem("access"),
      },
    })
      .then((resp) => {
        if (resp.ok) {
          console.log("address deleted");
          authObject.getaddresslist(authObject.user.id);
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setAd({ ...ad, [name]: value });
  }

  const handleCreate = (e) => {
    e.preventDefault();
    console.log("pressed submit on create");
    ad.user = authObject.user.id;
    ad.defaultshipping = false;
    ad.defaultbilling = false;
    setAd(ad);
    console.log("Address added :", ad);
    createAddress(ad);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    console.log("pressed submit on update");
    updateAddress(ad);
    setEditToggler(false);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    console.log("pressed delete");
    deleteAddress(ad);
    setDelToggler(false);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setEditToggler(false);
    setDelToggler(false);
    if (setCreateToggler) {
      setCreateToggler(false);
    }
  };

  return (
    <>
      {!editToggler && !delToggler && !create && (
        <div className="addressbox px-1 py-3">
          <div className="row ms-3">
            <div className="col-sm-6 fw-light">Naam</div>
            <div className="col-sm-6">
              <GetTitle code={add.title} /> {add.firstname} {add.name}
            </div>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-light">Straat Nr Ext</div>
            <div className="col-sm-6">
              {add.street} {add.hnr} {add.hnrext}
            </div>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-light">Postcode</div>
            <div className="col-sm-6">{add.zip}</div>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-light">Stad</div>
            <div className="col-sm-6">{add.city}</div>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-light">Land</div>
            <div className="col-sm-6">
              <GetCountry code={add.country} />
            </div>
          </div>
          {editdel && (
            <div className="row d-flex justify-content-evenly mt-3">
              <button
                className="btn btn-secondary btn-sm w-25 col-6"
                onClick={(e) => {
                  setAd(add);
                  setEditToggler(true);
                }}
              >
                Edit
              </button>
              <button
                className="btn btn-secondary btn-sm w-25 col-6"
                onClick={(e) => {
                  setAd(add);
                  setDelToggler(true);
                }}
              >
                Delete
              </button>
            </div>
          )}
        </div>
      )}
      {create && (
        <form
          className="addressbox py-3 px-1"
          onSubmit={(e) => handleCreate(e)}
        >
          <div className="row ms-3">
            <div className="col-sm-6 fw-light">Name</div>
            <div className="col-sm-6">
              <div>
                <select
                  className="form-control"
                  placeholder="title"
                  type="text"
                  id="title"
                  name="title"
                  value={ad.title}
                  onChange={handleChange}
                >
                  <option name="title" value="">
                    - title -
                  </option>
                  {titles &&
                    titles.map((tit, index) => {
                      return (
                        <option name="title" value={tit.code} key={index}>
                          {tit.name}
                        </option>
                      );
                    })}
                </select>

                <input
                  className="form-control"
                  placeholder="firstname"
                  type="text"
                  size="20"
                  id="firstname"
                  name="firstname"
                  value={ad.firstname}
                  onInput={handleChange}
                  pattern="[a-zA-Z0-9éèçà\-\s^<>]{1,255}$"
                />
                <input
                  className="form-control"
                  placeholder="name"
                  type="text"
                  size="20"
                  id="name"
                  name="name"
                  value={ad.name}
                  onInput={handleChange}
                  pattern="[a-zA-Z0-9éèçà\-\s^<>]{1,255}$"
                />
              </div>
            </div>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-light">Straat Nr Ext</div>
            <div className="col-sm-6">
              <input
                className="form-control"
                placeholder="straat"
                type="text"
                required
                id="street"
                name="street"
                value={ad.street}
                onInput={handleChange}
                pattern="[a-zA-Zéèçà()\s\-^<>]{1,200}$"
              ></input>
              <input
                className="form-control"
                placeholder="nummer"
                type="text"
                size="3"
                required
                id="hnr"
                name="hnr"
                value={ad.hnr}
                onInput={handleChange}
              ></input>
              <input
                className="form-control"
                placeholder="ext"
                type="text"
                id="hnrext"
                name="hnrext"
                value={ad.hnrext}
                onInput={handleChange}
              ></input>
            </div>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-light">Postcode</div>
            <div className="col-sm-6">
              <input
                className="form-control"
                placeholder="Postcode"
                type="text"
                required
                id="zip"
                name="zip"
                value={ad.zip}
                onInput={handleChange}
                pattern="{4,20}$"
              />
            </div>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-light">Stad</div>
            <div className="col-sm-6">
              <input
                className="form-control"
                placeholder="city"
                type="text"
                required
                id="city"
                name="city"
                value={ad.city}
                onInput={handleChange}
                pattern="[a-zA-Zéèçà()\s\-^<>]{1,100}$"
              />
            </div>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-light">Country</div>
            <div className="col-sm-6">
              <select
                className="form-control"
                placeholder="land"
                type="text"
                required
                id="country"
                name="country"
                value={ad.country}
                onInput={handleChange}
              >
                <option name="country" value="">
                  -- select a country --
                </option>
                {isocountries &&
                  isocountries.map((ic, index) => {
                    return (
                      <option name="country" value={ic.code} key={index}>
                        {ic.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div
            className="row mt-3 d-flex 
          justify-content-evenly"
          >
            <button
              className="btn btn-secondary btn-sm col-6 w-25"
              type="submit"
            >
              Create
            </button>
            <button
              className="btn btn-secondary btn-sm col-6 w-25"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </form>
      )}
      {editToggler && (
        <form
          className="addressbox py-3 px-1"
          onSubmit={(e) => handleUpdate(e)}
        >
          <div className="row ms-3">
            <div className="col-sm-6 fw-bold">Name</div>
            <div className="col-sm-6">
              <div className="form-group">
                <select
                  className="form-control"
                  placeholder="title"
                  type="text"
                  id="title"
                  name="title"
                  value={ad.title}
                  onChange={handleChange}
                >
                  <option name="title" value="">
                    - title -
                  </option>
                  {titles &&
                    titles.map((tit, index) => {
                      return (
                        <option name="title" value={tit.code} key={index}>
                          {tit.name}
                        </option>
                      );
                    })}
                </select>

                <input
                  className="form-control"
                  placeholder="firstname"
                  type="text"
                  size="20"
                  id="firstname"
                  name="firstname"
                  value={ad.firstname}
                  onInput={handleChange}
                  pattern="[a-zA-Z0-9éèçà\-\s^<>]{1,255}$"
                />
                <input
                  className="form-control"
                  placeholder="name"
                  type="text"
                  size="20"
                  id="name"
                  name="name"
                  value={ad.name}
                  onInput={handleChange}
                  pattern="[a-zA-Z0-9éèçà\-\s^<>]{1,255}$"
                ></input>
              </div>
            </div>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-bold">Straat Nr Ext</div>
            <div className="col-sm-6">
              <input
                className="form-control"
                placeholder="street"
                type="text"
                size="20"
                required
                id="street"
                name="street"
                value={ad.street}
                onInput={handleChange}
                pattern="[a-zA-Zéèçà()\s\-^<>]{1,200}$"
              />
              <input
                className="form-control"
                placeholder="nummer"
                type="text"
                size="3"
                required
                id="hnr"
                name="hnr"
                value={ad.hnr}
                onInput={handleChange}
                pattern="[0-9a-zA-Z]{1,10}$"
              ></input>
              <input
                className="form-control"
                placeholder="ext"
                type="text"
                size="2"
                id="hnrext"
                name="hnrext"
                value={ad.hnrext}
                onInput={handleChange}
                pattern="[0-9a-zA-Z]{1,10}$"
              ></input>
            </div>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-bold">Postcode</div>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                required
                id="zip"
                name="zip"
                value={ad.zip}
                onInput={handleChange}
                pattern="[0-9]{4,20}$"
              />
            </div>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-bold">Stad</div>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                required
                id="city"
                name="city"
                value={ad.city}
                onInput={handleChange}
                pattern="[a-zA-Zéèçà()\s\-^<>]{1,100}$"
              />
            </div>
          </div>

          <div className="row ms-3">
            <div className="col-sm-6 fst-italic">Land</div>
            <div className="col-sm-6">
              <select
                className="form-control"
                placeholder="country"
                type="text"
                required
                id="country"
                name="country"
                value={ad.country}
                onChange={handleChange}
              >
                <option name="country" value="">
                  -------
                </option>
                {isocountries &&
                  isocountries.map((ic, index) => {
                    return (
                      <option name="country" value={ic.code} key={index}>
                        {ic.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="row d-flex justify-content-evenly mt-3">
            <button
              className="btn btn-secondary btn-sm col-6 w-25"
              type="submit"
            >
              Save
            </button>
            <button
              className="btn btn-secondary btn-sm col-6 w-25"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </form>
      )}
      {delToggler && (
        <div className="addressbox py-3 px-1">
          <div className="row ms-3">
            <strong>Ben je zeker dat je dit adres wil verwijderen ?</strong>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-light">Straat Nr Ext</div>
            <div className="col-sm-6">
              {add.street} {add.hnr} {add.hnrext}
            </div>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-light">Postcode</div>
            <div className="col-sm-6">{add.zip}</div>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-light">Stad</div>
            <div className="col-sm-6">{add.city}</div>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-light">Land</div>
            <div className="addressbox_line_left">
              <GetCountry code={add.country} />
            </div>
          </div>
          <div className="row mt-3 d-flex justify-content-evenly">
            <button
              className="btn btn-danger btn-sm col-6 w-25"
              onClick={handleDelete}
            >
              Delete
            </button>
            <button
              className="btn btn-secondary btn-sm col-6 w-25"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AddressBox;
