import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Store/auth-context";
import ShopContext from "../Store/shop-context";

const FailPage = () => {
  const navigate = useNavigate();
  const authObject = useContext(AuthContext);
  const shopObject = useContext(ShopContext);

  if (!authObject.accesstoken) {
    navigate("/");
  }

  const abortOrder = () => {
    let pm = "A";
    shopObject.orderconfirmpayment(pm);
    authObject.createshopsession();
    navigate("/");
  };

  return (
    <div className="successpage">
      <div className="successpar fs-2">
        Spijtig genoeg is er iets misgegaan.
      </div>
      <div className="successpar">
        Kies een andere betalingsmethode of probeer opnieuw.
      </div>
      <div className="successpar">
        <button
          className="btn btn-primary successbutton"
          onClick={(e) => navigate("/paypage/")}
        >
          Terug naar betaalopties
        </button>
      </div>
      <div className="successpar">
        of wenst u de shop te verlaten en de bestelling te annuleren ?
      </div>
      <button
        className="btn btn-danger dangerbutton mb-4"
        onClick={(e) => abortOrder()}
      >
        Annuleren
      </button>
    </div>
  );
};

export default FailPage;
