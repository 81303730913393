function getNextDay(day) {
  const today = new Date();
  const todayday = today.getDay();
  const difference = todayday - day;
  const nextDayDate = new Date();
  if (todayday <= day) {
    nextDayDate.setDate(new Date(today).getDate() - difference);
  }
  if (todayday > day) {
    nextDayDate.setDate(new Date(today).getDate() + 7 - difference);
  }

  return nextDayDate;
}

export default getNextDay;
