import React from "react";
import GetDay from "./GetDay";

const DisplayDatefromPython = ({ date }) => {
  if (date) {
    date = new Date(date);
    let datestr = date.toDateString();
    let year = date.getYear() - 100;
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let dayweeknr = date.getDay();

    return (
      <>
        <GetDay code={dayweeknr} /> {day}/{month}/{year}
      </>
    );
  } else return <></>;
};

export default DisplayDatefromPython;
