import React from "react";

import { titles } from "../Data/data";

const GetTitle = ({ code }) => {
  if (titles.filter((tit) => tit.code == code)) {
    let titleName = titles.filter((tit) => tit.code == code)[0].name;
    return <>{titleName}</>;
  }
};

export default GetTitle;
