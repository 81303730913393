import React, { useState } from "react";

import useLayout from "../Functions/useLayout";

import PCStandardBody from "./PCStandardBody";
import PCCutToWeightBody from "./PCCutToWeightBody";
import PCFavorite from "./PCFavorite";

const ProductCard = ({ product, productoptions, clickDetail }) => {
  const [selectingOptions, setSelectingOptions] = useState(false);

  return (
    <div
      className="card"
      style={{ backgroundColor: useLayout(6, 0), color: useLayout(6, 1) }}
      id={"productcard-" + product.id}
    >
      <PCFavorite productid={product.id} />
      <div className="cardimagebox" onClick={(e) => clickDetail(product.id)}>
        <div>
          {product.picture == null ? (
            <div
              className="d-flex flex-wrap align-items-center justify-content-center bg-black text-white text-ce"
              style={{ width: 183, height: 150 }}
            >
              <div className="bg-black text-white h100 fs-5 text-center align-items-center">
                <div>{product.name}</div>
              </div>
              <div className="cardimageoverlay">
                <div className="overlaytext">Meer info</div>
              </div>
            </div>
          ) : (
            <>
              <img
                src={product.picture}
                className="cardimage"
                alt={product.name}
                width="200"
                height="150"
              />
              <div className="cardimageoverlay">
                <div className="overlaytext">Meer info</div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="card-body">
        {product.varianttype === "standard" && (
          <>
            <PCStandardBody
              product={product}
              productoptions={productoptions}
              selectingOptions={selectingOptions}
              setSelectingOptions={setSelectingOptions}
            />
          </>
        )}
        {product.varianttype === "cuttoweight" && (
          <>
            <PCCutToWeightBody
              product={product}
              productoptions={productoptions}
              selectingOptions={selectingOptions}
              setSelectingOptions={setSelectingOptions}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
