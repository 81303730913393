import React from "react";
import { isocountries } from "../Data/data";

const GetCountry = ({ code }) => {
  let countryName = isocountries.filter((country) => country.code == code)[0]
    .name;
  return <>{countryName}</>;
};

export default GetCountry;
