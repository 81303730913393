import React, { useState, useContext } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import AuthContext from "../Store/auth-context";

const ResetPasswordConfirm = () => {
  const authObject = useContext(AuthContext);

  const [requestSent, setRequestSent] = useState(false);
  const [formData, setFormData] = useState({
    new_password: "",
    re_new_password: "",
  });
  const { new_password, re_new_password } = formData;
  const { uid, token } = useParams();

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    authObject.resetpasswordconfirm(uid, token, new_password, re_new_password);
    setRequestSent(true);
  };

  if (requestSent) {
    return <Navigate to="/" />;
  }

  return (
    <div className="resetpwpage">
      <div className="h1 resetpwtitle">Reset Paswoord</div>
      <div className="resetpwline">
        Gelieve je paswoord twee maal in te vullen.
      </div>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="resetpwline">
          <label>
            Formaat (min 8 characters, hoofd-, kleine letter, cijfer en speciaal
            character (bvb -/_*)
          </label>
          <input
            className="resetpwfield"
            type="password"
            placeholder="New password"
            name="new_password"
            value={new_password}
            onChange={(e) => onChange(e)}
            pattern="(?=^.{8,255}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
            required
          />
        </div>
        <div className="resetpwline">
          <input
            className="resetpwfield"
            type="password"
            placeholder="Retype new password"
            name="re_new_password"
            value={re_new_password}
            onChange={(e) => onChange(e)}
            pattern={new_password}
            required
          />
        </div>
        <div className="resetpwline">
          <button className="btn btn-primary resetpwbutton" type="submit">
            Bevestig
          </button>
        </div>
      </form>
      <p className="resetpwline">
        Heb je nog geen account ? <Link to="/signup/">Registreer</Link>
      </p>
    </div>
  );
};

export default ResetPasswordConfirm; // connect to wrap Reset Password Confirm page, as no mapStateToProps are used, and reset_password_confirm function is used
