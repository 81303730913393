import React, { useState, useEffect } from "react";
import useLayout from "../Functions/useLayout";

const ClickPop = ({ pop, setPop, setToggler }) => {
  useEffect(() => {
    setTimeout(() => {
      setPop(false);
      if (setToggler) {
        setToggler(false);
      }
    }, 1000);
  }, [pop]);

  return (
    <div style={{ backgroundColor: useLayout(7, 0), color: useLayout(7, 1) }}>
      {pop && (
        <>
          <div className="clickpop">Toegevoegd aan winkelmandje</div>
        </>
      )}
    </div>
  );
};

export default ClickPop;
