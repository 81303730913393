import React, { useState, useContext, useEffect } from "react";
import DetailAllergens from "./DetailAllergens";
import DetailVW from "./DetailVW";
import useLayout from "../Functions/useLayout";

import AuthContext from "../Store/auth-context";

const ProductDetail = ({ prod, setProd, productoptions, setScrollItem }) => {
  const [product, setProduct] = useState([]);
  const [allergens, setAllergens] = useState();
  const [vw, setVw] = useState();
  const [image, setImage] = useState();
  const authObject = useContext(AuthContext);
  const [selectingOptions, setSelectingOptions] = useState(false);

  const loadProductDetail = async (product) => {
    var url = process.env.REACT_APP_API_URL + "api/product-detail/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        webshop: authObject.webshoptoken,
        product,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setProduct(resp);
        setImage(resp.picturelarge1);
        if (resp.allergens !== null) {
          loadProductAllergens(product);
        }
        if (resp.voedingswaarden !== null) {
          loadProductVoedingsWaarden(product);
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const loadProductAllergens = async (product) => {
    var url = process.env.REACT_APP_API_URL + "api/product-allergens/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        webshop: authObject.webshoptoken,
        product,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setAllergens(resp);
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const loadProductVoedingsWaarden = async (product) => {
    var url = process.env.REACT_APP_API_URL + "api/product-voedingswaarden/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        webshop: authObject.webshoptoken,
        product,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setVw(resp);
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  useEffect(() => {
    loadProductDetail(prod);
    document
      .getElementById("productdetail")
      .scrollIntoView({ behavior: "smooth" });
  }, [prod]);

  function clickBack() {
    var pid = "productcard-" + prod;
    setScrollItem(pid);
    prod = [];
    setProd(false);
  }

  return (
    <div
      className="detailpage py-3 px-1"
      style={{ backgroundColor: useLayout(6, 0), color: useLayout(6, 1) }}
    >
      {product && (
        <>
          <div className="row" id="productdetail">
            <div className="col-sm-6">
              <button
                className="btn btn-secondary btn-sm"
                onClick={(e) => clickBack()}
              >
                {"<"}-- terug
              </button>
              <div className="h1 dptitle mt-2 text-center">{product.name}</div>
              <div className="dppicbox">
                <img src={image} className="dpbox" alt={product.title}></img>
              </div>
              <div className="dpthumbgroup">
                <div
                  className="dpthumb"
                  onClick={(e) => setImage(product.picturelarge1)}
                >
                  <img
                    src={product.picturelarge1}
                    className="dpthumb"
                    alt={product.title}
                  ></img>
                </div>
                <div
                  className="dpthumb"
                  onClick={(e) => setImage(product.picturelarge2)}
                >
                  <img
                    src={product.picturelarge2}
                    className="dpthumb"
                    alt={product.title}
                  ></img>
                </div>
                <div
                  className="dpthumb"
                  onClick={(e) => setImage(product.picturelarge3)}
                >
                  <img
                    src={product.picturelarge3}
                    className="dpthumb"
                    alt={product.title}
                  ></img>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="dpdetailsshort mt-3 text-center">
                {product.description}
              </div>
              <div>
                <div className="dpdetailsfull px-1">
                  {product.fulldescription}
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="row">
            <div className="col-sm-6">
              {prod.allergens !== null && allergens && (
                <>
                  <DetailAllergens allergens={allergens} />
                </>
              )}
            </div>
            <div className="col-sm-6">
              {prod.voedingswaarden !== null && vw && (
                <>
                  <DetailVW vw={vw} />
                </>
              )}
            </div>
          </div>
          <button
            className="btn btn-secondary btn-sm"
            onClick={(e) => {
              clickBack();
            }}
          >
            {"<"}-- terug
          </button>
        </>
      )}
    </div>
  );
};

export default ProductDetail;
