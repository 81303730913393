import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../Store/auth-context";
import ShopContext from "../Store/shop-context";
import ClickPop from "./ClickPop";
import ShowProductSetting from "./ShowProductSetting";

import roundOff from "../Functions/roundOff";

const OrderStandardCounter = ({ product, productoptions, setToggler }) => {
  const authObject = useContext(AuthContext);
  const shopObject = useContext(ShopContext);
  var item = {
    product: product.id,
    cartitemvat: product.vat,
    name: product.name,
    description: product.description,
    sku: product.sku,
  };
  const [counter, setCounter] = useState(1);
  var [pop, setPop] = useState(false);

  function handlePlus() {
    setCounter(counter + 1);
  }

  function handleMin() {
    counter >= 1 ? setCounter(counter - 1) : setCounter(0);
  }

  const onChange = (e) => {
    e.target.value >= 0 ? setCounter(parseInt(e.target.value)) : setCounter(0);
  };

  const Addtocart = (item) => {
    if (counter === 0) {
      return;
    }
    item.quantity = counter;
    if (productoptions && productoptions.length > 0) {
      productoptions.map(
        (po, index) => (item.cartitemprice = product.price + po.price)
      );
    } else {
      item.cartitemprice = product.price;
    }
    item.cartitemtotal = roundOff(item.cartitemprice * item.quantity, 2);
    item.shopsessiontoken = authObject.shopsessiontoken;
    item.webshop = authObject.webshoptoken;
    item.varianttype = product.varianttype;
    if (!productoptions) {
      shopObject.cartitemcreate(item);
    }
    if (productoptions) {
      shopObject.cartitemcreatewoptions(item, productoptions);
    }
    setCounter(1);
    // if (setToggler) {
    //   setToggler(false);
    // }
  };

  return (
    <>
      <div className="row">
        <div className="col-6 d-flex align-items-center">
          <div className="plusminbuttongroup ms-1">
            <button
              className="text-center m-0 p-0 bg-success text-white border-0"
              onClick={handlePlus}
            >
              <strong>+</strong>
            </button>
            <input
              className="qtytextinput border-0 text-center fw-bold"
              type="number"
              min="0"
              value={counter}
              onChange={onChange}
              aria-label={`hoeveelheid voor ${product.name}`}
            />
            <button
              className="text-center m-0 p-0 bg-success text-white border-0"
              onClick={handleMin}
            >
              <strong>-</strong>
            </button>
          </div>
          <div className="mt-3 ms-3">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                pop = true;
                setPop(true);
                Addtocart(item);
              }}
            >
              {pop && (
                <ClickPop pop={pop} setPop={setPop} setToggler={setToggler} />
              )}
              Bestel
            </button>
          </div>
        </div>
      </div>
      <div className="restrictions mt-3 text-end">
        {product &&
          product.settings.length > 0 &&
          shopObject.productsettings.length > 0 && (
            <>
              Opgelet !
              <div className="restrictiontext">
                <ShowProductSetting
                  prodsettings={product.settings}
                  allsettings={shopObject.productsettings}
                />
              </div>
            </>
          )}
      </div>
    </>
  );
};

export default OrderStandardCounter;
