import React from "react";

const getStatus = (order) => {
  var textstring = "";
  switch (order.shipmethod) {
    case "P":
      if (order.shipped) {
        textstring = "opgehaald op " + order.shipped.slice(0, 10);
      } else {
        if (order.readyforship) {
          textstring =
            "bestelling is klaargemaakt voor ophaling, zie orderbevestiging voor details";
        } else {
          textstring = "bestelling moet nog klaargemaakt worden";
        }
      }
      break;
    case "M":
      if (order.shipped) {
        textstring =
          "verzonden op " +
          order.shipped.slice((0, 10)) +
          " tracking " +
          order.barcode;
      } else {
        if (order.readyforship) {
          textstring =
            "bestelling is klaar voor verzending op " +
            order.readyforship.slice(0, 10);
        } else {
          textstring = "bestelling moet nog klaargemaakt worden";
        }
      }
      break;
    case "D":
      if (order.shipped) {
        textstring = "klaar voor download op " + order.shipped.slice(0, 10);
      }
      break;
  }
  return textstring;
};

export default getStatus;
