import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthContext from "../Store/auth-context";
import ShopContext from "../Store/shop-context";

import CartCounter from "../Components/CartCounter";
import GetTitle from "../Components/GetTitle";
import Cart from "./Cart";
import { useNavigate } from "react-router-dom";
import useLayout from "../Functions/useLayout";

const Header = () => {
  const navigate = useNavigate();
  let authObject = useContext(AuthContext);
  let shopObject = useContext(ShopContext);
  const loc = useLocation();
  const bgcolor = useLayout(1, 0);
  const color = useLayout(1, 1);

  const logout_user = () => {
    navigate("/");
    authObject.logoutuser();
  };

  function proceedHandler() {
    shopObject.setshowcart(false);
    shopObject.setcheckout(true);
    authObject.setshipaddress(
      authObject.addresslist.filter(
        (address) => address.defaultshipping === true
      )[0]
    );
    authObject.setbilladdress(
      authObject.addresslist.filter(
        (address) => address.defaultbilling === true
      )[0]
    );
    navigate("/checkout/");
  }

  function goHome() {
    if (loc.pathname === "/") {
      window.location.reload();
    } else {
      shopObject.setworkcat(null);
      navigate("/");
    }
  }

  return (
    <>
      {authObject.organisation && (
        <>
          <div
            className="shopheader"
            style={{ backgroundColor: bgcolor, color: color }}
          >
            <nav className="">
              {shopObject.showcart && (
                <>
                  <Cart />
                </>
              )}
              <div className="container-fluid d-flex justify-content-evenly">
                {/* --------------------brand-------------------- */}
                <div className="brand fs-3" onClick={(e) => goHome()}>
                  {authObject.organisation.brand &&
                    authObject.organisation.brand}
                  {authObject.organisation.smallogo && <></>}
                </div>

                {/* --------------------ga door/login knop-------------------- */}
                <div
                  className="d-flex align-items-center"
                  style={{ backgroundColor: bgcolor, Color: color }}
                >
                  {!shopObject.checkout &&
                    shopObject.cart.length > 0 &&
                    authObject.user && (
                      <>
                        <button
                          className="btn btn-success continuebutton"
                          onClick={() => proceedHandler()}
                        >
                          Ga door
                        </button>
                      </>
                    )}
                  {shopObject.cart.length > 0 && !authObject.user && (
                    <>
                      <button
                        className="nav-item btn btn-danger"
                        onClick={() => navigate("/login")}
                      >
                        Inloggen / Registreren
                      </button>
                    </>
                  )}
                  {shopObject.cart.length === 0 && !authObject.user && (
                    <>
                      <div className="nav-item">
                        <Link
                          className="nav-link navtext ms-5"
                          to="/login"
                          style={{ backgroundColor: bgcolor, color: color }}
                        >
                          Inloggen/Registreren
                        </Link>
                      </div>
                    </>
                  )}
                </div>
                <div
                  className="d-flex flex-row align-items-center"
                  style={{ backgroundColor: bgcolor, Color: color }}
                >
                  {/* --------------------user dropdown-------------------- */}
                  <div
                    className="me-2"
                    style={{ backgroundColor: bgcolor, Color: color }}
                  >
                    <ul
                      className="navbar-nav me-auto mb-2 mb-lg-0 navtext"
                      style={{ backgroundColor: bgcolor, Color: color }}
                    >
                      <>
                        {authObject.user ? (
                          <>
                            <li className="nav-item dropdown">
                              <a
                                className="nav-link dropdown-toggle navtext"
                                style={{
                                  backgroundColor: bgcolor,
                                  color: color,
                                }}
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <small>
                                  <i
                                    className="bi bi-person"
                                    style={{
                                      backgroundColor: bgcolor,
                                      color: color,
                                    }}
                                  ></i>
                                </small>
                              </a>
                              <ul
                                className="dropdown-menu"
                                style={{
                                  backgroundColor: bgcolor,
                                  color: color,
                                }}
                              >
                                <span
                                  style={{
                                    backgroundColor: bgcolor,
                                    color: color,
                                  }}
                                >
                                  Welkom{" "}
                                  <GetTitle code={authObject.user.title} />{" "}
                                  {authObject.user.name}
                                </span>
                                <li>
                                  <Link
                                    className="dropdown-item navtext"
                                    to="/userPage/"
                                    style={{
                                      backgroundColor: bgcolor,
                                      color: color,
                                    }}
                                  >
                                    Uw details
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item navtext"
                                    to="/orderhistorypage/"
                                    style={{
                                      backgroundColor: bgcolor,
                                      color: color,
                                    }}
                                  >
                                    Uw bestellingen
                                  </Link>
                                </li>
                                <li>
                                  <div
                                    className="dropdown-item navtext hovertext"
                                    onClick={logout_user}
                                    style={{
                                      backgroundColor: bgcolor,
                                      color: color,
                                    }}
                                  >
                                    Uitloggen
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    </ul>
                  </div>

                  {/* --------------------Cart knop-------------------- */}
                  <div>
                    {!shopObject.checkout && shopObject.cart.length > 0 && (
                      <>
                        {!shopObject.showcart && (
                          <>
                            <li
                              className="nav-item nav-link navtext"
                              onClick={(e) => shopObject.setshowcart(true)}
                              style={{ backgroundColor: bgcolor, color: color }}
                            >
                              <CartCounter />
                              {/* <Cart /> */}
                            </li>
                          </>
                        )}
                        {shopObject.showcart && (
                          <>
                            <li className="nav-item nav-link navtext">
                              <CartCounter />
                              {/* <Cart /> */}
                            </li>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </>
      )}
    </>
  );
};

export default Header;
