import React, { useState, useEffect, useContext } from "react";
import ShopContext from "../Store/shop-context";
import ClickPop from "./ClickPop";

import roundOff from "../Functions/roundOff";

const OrderCTWOptions = ({
  product,
  productoptions,
  item,
  setItem,
  setToggler,
}) => {
  const shopObject = useContext(ShopContext);
  var [pop, setPop] = useState(false);
  var selectedoptions = [];
  let oldproductprice = item.cartitemtotal * (1 + item.cartitemvat);
  const [checkedState, setCheckedState] = useState(
    new Array(productoptions.length).fill(false)
  );

  useEffect(() => {
    if (productoptions) {
      productoptions.map((po, index) => (po.selected = false));
    }
    setCheckedState(new Array(productoptions.length).fill(false));
  }, []);

  const [total, setTotal] = useState(
    item.cartitemprice * (1 + item.cartitemvat) * item.quantity
  );

  const handleOnChange = (position) => {
    var updatedCheckedState = [];

    // Multiple options allowed
    if (product.multioption === true) {
      updatedCheckedState = checkedState.map((item, index) =>
        index === position ? !item : item
      );
    }

    // Multiple options NOT allowed
    if (product.multioption === false) {
      updatedCheckedState = checkedState.map((item, index) =>
        index === position ? !item : false
      );
    }
    setCheckedState(updatedCheckedState);
    const totalPrice = updatedCheckedState.reduce(
      (sum, currentState, index) => {
        if (currentState === true) {
          productoptions[index].selected = true;
          return (
            sum + productoptions[index].price * (1 + productoptions[index].vat)
          );
        }
        if (currentState === false) {
          productoptions[index].selected = false;
        }
        return sum;
      },
      0
    );
    setTotal(roundOff(oldproductprice, 2) + totalPrice);
  };

  const AddtocartWithOptions = () => {
    var newprice = item.cartitemtotal;
    if (productoptions && productoptions.length > 0) {
      productoptions
        .filter((po) => po.selected === true)
        .map((po, index) => {
          newprice += po.price;
        });
    }
    item.cartitemtotal = newprice;
    item.cartitemprice = newprice;
    var selectedoptions = productoptions.filter((po) => po.selected === true);
    if (selectedoptions.length === 0) {
      shopObject.cartitemcreate(item);
    }
    if (selectedoptions.length > 0) {
      shopObject.cartitemcreatewoptions(item, selectedoptions);
    }
  };

  return (
    <div className="card-options">
      <div className="row fw-bold">
        <div className="col-12">
          {item.weight} gram - Totaal {roundOff(oldproductprice, 2)} €
        </div>
      </div>
      <div className="row mt-1">Keuze mogelijkheden :</div>

      <div className="form-check card-optionlist">
        {productoptions
          .sort((a, b) => a.description.localeCompare(b.description))
          .map((po, index) => {
            return (
              <div key={index}>
                <input
                  className="form-check-input card-optionselector"
                  type="checkbox"
                  id={`custom-checkbox-${po.id}`}
                  name={po.description}
                  value={po.description}
                  checked={checkedState[index]}
                  onChange={() => handleOnChange(index)}
                />
                <label htmlFor={`custom-checkbox-${po.id}`}>
                  {po.description}
                  {po.price !== 0 ? (
                    <> : {(po.price * (1 + po.vat)).toFixed(2)}</>
                  ) : (
                    <></>
                  )}
                </label>
              </div>
            );
          })}
      </div>
      <div className="row mt-2 mb-2">
        <div className="col-7">Totaal</div>
        <div className="col-5">
          <strong>{total.toFixed(2)}</strong>
        </div>
      </div>
      <div className="mt-3 ms-3">
        <button
          className="btn btn-primary"
          onClick={(e) => {
            pop = true;
            setPop(true);
            AddtocartWithOptions();
          }}
        >
          {pop && (
            <ClickPop pop={pop} setPop={setPop} setToggler={setToggler} />
          )}
          Bestel
        </button>
      </div>
    </div>
  );
};

export default OrderCTWOptions;
