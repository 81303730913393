import React from "react";

import { days } from "../Data/data";

const GetDay = ({ code }) => {
  if (days.filter((day) => day.code === code)) {
    let dayName = days.filter((day) => day.code == code)[0].name;
    return <>{dayName}</>;
  }
};

export default GetDay;
