import React, { useState } from "react";
import { useEffect } from "react";
import OrderStandardCounter from "./OrderStandardCounter";

const OrderOptions = ({ product, productoptions, setToggler }) => {
  let oldproductprice = product.price * (1 + product.vat);
  const [checkedState, setCheckedState] = useState(
    new Array(productoptions.length).fill(false)
  );

  useEffect(() => {
    if (productoptions) {
      productoptions.map((po, index) => (po.selected = false));
    }
    setCheckedState(new Array(productoptions.length).fill(false));
  }, []);

  const [total, setTotal] = useState(product.price * (1 + product.vat));

  const handleOnChange = (position) => {
    var updatedCheckedState = [];

    // Multiple options allowed
    if (product.multioption === true) {
      updatedCheckedState = checkedState.map((item, index) =>
        index === position ? !item : item
      );
    }

    // Multiple options NOT allowed
    if (product.multioption === false) {
      updatedCheckedState = checkedState.map((item, index) =>
        index === position ? !item : false
      );
    }

    setCheckedState(updatedCheckedState);
    const totalPrice = updatedCheckedState.reduce(
      (sum, currentState, index) => {
        if (currentState === true) {
          productoptions[index].selected = true;
          return (
            sum + productoptions[index].price * (1 + productoptions[index].vat)
          );
        }
        if (currentState === false) {
          productoptions[index].selected = false;
        }
        return sum;
      },
      0
    );
    setTotal(oldproductprice + totalPrice);
  };

  return (
    <div className="card-options">
      <div className="row">
        <div className="col-7">Prijs</div>
        <div className="col-5">
          <strong>{oldproductprice.toFixed(2)}</strong>
        </div>
      </div>
      <div className="row mt-1">Keuze mogelijkheden :</div>

      <div className="form-check card-optionlist">
        {productoptions
          .sort((a, b) => a.description.localeCompare(b.description))
          .map((po, index) => {
            return (
              <div key={index}>
                <input
                  className="form-check-input card-optionselector"
                  type="checkbox"
                  id={`custom-checkbox-${po.id}`}
                  name={po.description}
                  value={po.description}
                  checked={checkedState[index]}
                  onChange={() => handleOnChange(index)}
                />
                <label htmlFor={`custom-checkbox-${po.id}`}>
                  {po.description}
                  {po.price !== 0 ? (
                    <> : {(po.price * (1 + po.vat)).toFixed(2)}</>
                  ) : (
                    <>{""}</>
                  )}
                </label>
              </div>
            );
          })}
      </div>
      <div className="row mt-2 mb-2">
        <div className="col-7">Totaal</div>
        <div className="col-5">
          <strong>{total.toFixed(2)}</strong>
        </div>
      </div>
      <OrderStandardCounter
        product={product}
        productoptions={productoptions.filter((po) => po.selected === true)}
        setToggler={setToggler}
      />
    </div>
  );
};

export default OrderOptions;
