import React, { useState, useEffect } from "react";
import { useContext } from "react";
import AuthContext from "../Store/auth-context";
import ShopContext from "../Store/shop-context";
import ShowProductSetting from "./ShowProductSetting";
import GetDay from "./GetDay";
import GetTime from "./GetTime";

const ToggleOptions = ({ product, setToggler }) => {
  const shopObject = useContext(ShopContext);

  return (
    <>
      <div className="d-flex align-items-center mt-3 p-2 justify-content-center">
        <button
          className="btn btn-primary btn-sm"
          onClick={(e) => {
            setToggler(true);
          }}
        >
          Kies Opties en Bestel
        </button>
      </div>

      <div className="row mt-2">
        <div className="col-5"></div>
        <div className="restrictions col-6">
          {product &&
            product.settings.length > 0 &&
            shopObject.productsettings.length > 0 && (
              <>
                Opgelet !
                <div className="restrictiontext">
                  <ShowProductSetting
                    prodsettings={product.settings}
                    allsettings={shopObject.productsettings}
                  />
                </div>
              </>
            )}
        </div>
      </div>
    </>
  );
};

export default ToggleOptions;
