import React, { useState, useEffect } from "react";

//Routing
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//Context
import { AuthProvider } from "./Store/auth-context";
import { ShopContextProvider } from "./Store/shop-context";

//Segments
import Header from "./Segments/Header";
//Pages
import Home from "./Pages/Home";
import LoginPage from "./Pages/LoginPage";
import LoginAfterSignupPage from "./Pages/LoginAfterSignupPage";
import CheckOutPage from "./Pages/CheckOutPage";
import OrderConfirmPage from "./Pages/OrderConfirmPage";
import PaymentPage from "./Pages/PaymentPage";
import UserPage from "./Pages/UserPage";
import OrderHistoryPage from "./Pages/OrderHistoryPage";
import SuccessPage from "./Pages/SuccessPage";
import FailPage from "./Pages/FailPage";
import AbortPage from "./Pages/AbortPage";
import Signup from "./Pages/Signup";
import ResetPassword from "./Pages/ResetPassword";
import Activate from "./Pages/Activate";
import ResetPasswordConfirm from "./Pages/ResetPasswordConfirm";

// Styles
import "./index.css";

const App = () => {
  const [layout, setLayout] = useState();
  const [textcolor, setTextcolor] = useState("");
  const [bgcolor, setBgcolor] = useState("");

  useEffect(() => {
    getShopOptions();
  }, []);

  useEffect(() => {
    if (layout) {
      setBgcolor(layout[0]["colors"][0].rgba);
      setTextcolor(layout[0]["colors"][1].rgba);
    }
  }, [layout]);

  const getShopOptions = async () => {
    var url = process.env.REACT_APP_API_URL + "api/shopoptions/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        webshop: process.env.REACT_APP_SHOP_REF,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setLayout(JSON.parse(resp.layout));
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  return (
    <Router>
      <AuthProvider>
        <ShopContextProvider>
          <div
            className="shop"
            style={{ backgroundColor: bgcolor, color: textcolor }}
          >
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login/" element={<LoginPage />} />
              <Route
                path="/loginaftersignup/"
                element={<LoginAfterSignupPage />}
              />
              <Route path="/checkout/" element={<CheckOutPage />} />
              <Route path="/confirm/" element={<OrderConfirmPage />} />
              <Route path="/paypage/" element={<PaymentPage />} />
              <Route path="/userpage/" element={<UserPage />} />
              <Route path="/orderhistorypage/" element={<OrderHistoryPage />} />
              <Route path="/successpage/" element={<SuccessPage />} />
              <Route path="/failpage/" element={<FailPage />} />
              <Route path="/abortpage/" element={<AbortPage />} />
              <Route path="/signup/" element={<Signup />} />
              <Route path="/reset-password/" element={<ResetPassword />} />
              <Route path="/activate/:uid/:token/" element={<Activate />} />
              <Route
                path="/password/reset/confirm/:uid/:token/"
                element={<ResetPasswordConfirm />}
              />
            </Routes>
          </div>
        </ShopContextProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
