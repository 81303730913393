import React, { Fragment, useState } from "react";
import GetCountry from "./GetCountry";
import useLayout from "../Functions/useLayout";

const ShopCheckoutLocations = ({ loc, selectLocation, setSelectLocation }) => {
  const [checkBox, setCheckBox] = useState(false);
  const bg = useLayout(9, 0);
  const color = useLayout(9, 1);

  const handleChange = (e) => {
    setCheckBox(e.target.value);
    setSelectLocation(e.target.value);
  };
  const clickButton = (value) => {
    setSelectLocation(value);
  };

  return (
    <>
      <div className="ccosubtitle">
        Beschikbare locaties - Klik op uw voorkeur
      </div>
      <div className="ccosubtitle fst-italic text-center mt-0 mb-1 fw-normal">
        <small>klik op uw voorkeur om de kalender aan te passen</small>
      </div>
      <div className="ccoline"></div>
      <div className="ccotextbox">
        {loc && loc.length > 1 && selectLocation !== 0 && (
          <>
            <button
              className="ccolocationbox d-flex justify-content-center"
              onClick={() => clickButton(0)}
              style={{ backgroundColor: bg, color: color }}
            >
              <div className="ccoline text-center">
                <strong>Toon alle locaties</strong>
              </div>
            </button>
          </>
        )}

        {loc &&
          loc.map((loc, index) => {
            return (
              <Fragment key={index}>
                {(selectLocation === loc.id || selectLocation === 0) && (
                  <>
                    <button
                      key={index}
                      className="ccolocationbox"
                      style={{ backgroundColor: bg, color: color }}
                      onClick={() => clickButton(loc.id)}
                    >
                      <div className="ccoline text-center">
                        <strong>{loc.name}</strong>
                      </div>
                      <div className="ccoline text-center mx-auto">
                        <span>
                          {" "}
                          {loc.street} {loc.hnr} {loc.hnrext}
                        </span>
                      </div>
                      <div className="ccoline">
                        {loc.zip} {loc.city} {<GetCountry code={loc.country} />}
                      </div>
                    </button>
                  </>
                )}
              </Fragment>
            );
          })}
      </div>
    </>
  );
};

export default ShopCheckoutLocations;
