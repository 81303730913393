import React from "react";
import GetDay from "./GetDay";
import GetTime from "./GetTime";

const ShowProductSetting = ({ prodsettings, allsettings }) => {
  return (
    <>
      {prodsettings.map((prodset, index) => {
        var setting = allsettings.filter((alset) => alset.id === prodset)[0];
        if (setting.leadtime) {
          return (
            <div key={index}>
              <i className="bi bi-arrow-right"></i> {setting.leadtime} dag(en)
              op voorhand bestellen
            </div>
          );
        }
        if (!setting.leadtime) {
          return (
            <div key={index}>
              <i className="bi bi-arrow-right"></i> Bestellen vóór{" "}
              <GetDay code={new Date(setting.orderdeadline).getDay()} />{" "}
              <GetTime date={setting.orderdeadline} /> u, beschikbaar vanaf{" "}
              <GetDay code={new Date(setting.orderdelivery).getDay()} />{" "}
              <GetTime date={setting.orderdelivery} /> u
            </div>
          );
        }
      })}
    </>
  );
};

export default ShowProductSetting;
