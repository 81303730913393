import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AuthContext from "../Store/auth-context";
import ShopContext from "../Store/shop-context";

import Addresslist from "../Components/Addresslist";
import BillAddressDetails from "../Components/BillAddressDetails";
import ShipAddressDetails from "../Components/ShipAddressDetails";
import UserBox from "../Components/UserBox";
import PNLCheckout from "../PostNL/PNLCheckout";
import ShopCheckout from "../Segments/ShopCheckout";
import getLayout from "../Functions/getLayout";

const CheckOutPage = () => {
  const authObject = useContext(AuthContext);
  const shopObject = useContext(ShopContext);

  const [shipToggler, setShipToggler] = useState(false);
  const [billToggler, setBillToggler] = useState(false);

  const [pnlToggler, setPnlToggler] = useState(false);
  const [shopLocToggler, setShopLocToggler] = useState(false);

  const [DHLToggler, setDHLToggler] = useState(false);

  var [layout, setLayout] = useState();
  var navigate = useNavigate();

  useEffect(() => {
    if (
      shopObject.shopoptions.en_pickup &&
      !shopObject.shopoptions.en_shipping
    ) {
      setShopLocToggler(true);
    }
    if (
      !shopObject.shopoptions.en_pickup &&
      shopObject.shopoptions.en_shipping
    ) {
      setPnlToggler(true);
    }

    document
      .getElementById("checkoutpage")
      .scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (shopObject.shopoptions.layout) {
      setLayout(JSON.parse(shopObject.shopoptions.layout));
    }
  }, [shopObject.shopoptions.layout]);

  function checkout() {
    authObject.setbilladdress({
      ...authObject.billaddress,
      ["title"]: authObject.user.title,
      ["firstname"]: authObject.user.firstname,
      ["name"]: authObject.user.name,
    });
    navigate("/confirm/");
  }

  if (!authObject.accesstoken) {
    navigate("/");
  }

  function backToStore() {
    shopObject.setworkcat(null);
    shopObject.setworksubcat(null);
    navigate("/");
  }

  return (
    <div
      className="checkoutpage mx-auto px-1 py-5"
      style={{
        backgroundColor: getLayout(layout, 8, 0),
        color: getLayout(layout, 8, 1),
      }}
      id="checkoutpage"
    >
      <div className="checkoutbuttongroup">
        <button
          className="btn btn-secondary gobackbutton"
          onClick={() => backToStore()}
        >
          Terug naar winkel
        </button>
        <div></div>
      </div>
      <div className="h1 checkouttitle text-center">Uitchecken</div>

      {!pnlToggler && !shopLocToggler && (
        <div className="mx-auto w-75">
          <div className="mx-auto fs-2 text-center">
            Check je gegevens en maak een keuze
          </div>
          <div className="d-flex justify-content-between mx-auto">
            <div>
              <button
                className="btn btn-primary proceedbutton"
                onClick={(e) => {
                  setPnlToggler(true);
                }}
              >
                Verzending toevoegen
              </button>
            </div>
            <div>of</div>
            <div>
              <button
                className="btn btn-primary proceedbutton"
                onClick={(e) => {
                  setShopLocToggler(true);
                }}
              >
                Afhalen op locatie
              </button>
            </div>
          </div>
        </div>
      )}

      {pnlToggler && (
        <>
          <PNLCheckout toggler={pnlToggler} setToggler={setPnlToggler} />
        </>
      )}

      {shopLocToggler && (
        <>
          <ShopCheckout
            toggler={shopLocToggler}
            setToggler={setShopLocToggler}
          />
        </>
      )}

      {!pnlToggler && !shopLocToggler && (
        <div>
          <UserBox />
        </div>
      )}

      {pnlToggler && (
        <>
          <div>
            <div className="addressbox mt-3 px-1">
              <div className="addresstitleline">
                <div className="mx-auto">
                  <div className="h3 addressboxtitle">Facturatie Adres</div>
                </div>
                {!billToggler && (
                  <div>
                    <button
                      className="btn btn-secondary btn-sm m-1"
                      onClick={(e) => setBillToggler(!billToggler)}
                    >
                      Kies uit lijst
                    </button>
                  </div>
                )}
              </div>
              {!billToggler && authObject.billaddress && <BillAddressDetails />}
              {billToggler && (
                <div>
                  <Addresslist
                    changebilling={true}
                    toggler={billToggler}
                    setToggler={setBillToggler}
                  />
                </div>
              )}
            </div>
            <div className="addressbox mt-3 px-1">
              <div className="addresstitleline">
                <div className="mx-auto">
                  <h3 className="h3 addressboxtitle">Verzendadres</h3>
                </div>
                {!shipToggler && (
                  <div>
                    <button
                      className="btn btn-secondary btn-sm m-1"
                      onClick={(e) => setShipToggler(!shipToggler)}
                    >
                      Kies uit lijst
                    </button>
                  </div>
                )}
              </div>
              {!shipToggler && !DHLToggler && authObject.shipaddress && (
                <ShipAddressDetails />
              )}
              {shipToggler && !DHLToggler && (
                <div>
                  <Addresslist
                    changebilling={false}
                    toggler={shipToggler}
                    setToggler={setShipToggler}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CheckOutPage;
