import React, { useState, useContext, useEffect } from "react";
import ShopContext from "../Store/shop-context";

const CartCounter = () => {
  const [counter, setCounter] = useState(0);

  const shopObject = useContext(ShopContext);

  const countitems = () => {
    let count = 0;
    shopObject.cart.map((c, index) => {
      count += c.quantity;
    });
    setCounter(count);
  };

  useEffect(() => {
    countitems();
  }, [shopObject.cart]);

  return (
    <>
      <div>
        <i className="bi bi-basket"></i> {counter}
      </div>
    </>
  );
};

export default CartCounter;
