import getNextDay from "./getNextDay";

function getNextDeliveryDay(setting) {
  console.log("setting.orderdeadline:", setting.orderdeadline);
  console.log("setting.orderdelivery:", setting.orderdelivery);
  console.log("orderdeadline :", new Date(setting.orderdeadline).getDay());
  console.log("orderdelivery :", new Date(setting.orderdelivery).getDay());
  const nextorderdate = getNextDay(new Date(setting.orderdeadline).getDay());
  console.log("nextorderdate :", nextorderdate);

  var nextdeliverydate = getNextDay(new Date(setting.orderdelivery).getDay());
  console.log("nextdeliverydate :", nextdeliverydate);
  if (nextdeliverydate.getTime() < nextorderdate.getTime()) {
    nextdeliverydate.setDate(new Date(nextdeliverydate).getDate() + 7);
  }

  return nextdeliverydate;
}

export default getNextDeliveryDay;
