import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AuthContext from "../Store/auth-context";
import ShopContext from "../Store/shop-context";

import Addresslist from "../Components/Addresslist";
import BillAddressDetails from "../Components/BillAddressDetails";
import ShipAddressDetails from "../Components/ShipAddressDetails";
import UserBox from "../Components/UserBox";
import AddressBox from "../Components/AddressBox";
import getLayout from "../Functions/getLayout";

import { blankaddress } from "../Data/data";

const UserPage = () => {
  const authObject = useContext(AuthContext);
  const shopObject = useContext(ShopContext);

  const [mailToggler, setMailToggler] = useState(false);

  const [createAdToggler, setCreateAdToggler] = useState(false);

  const [shipToggler, setShipToggler] = useState(false);
  const [billToggler, setBillToggler] = useState(false);
  const [layout, setLayout] = useState();

  const newAd = blankaddress;

  useEffect(() => {
    if (shopObject.shopoptions.layout) {
      setLayout(JSON.parse(shopObject.shopoptions.layout));
    }
  }, [shopObject.shopoptions.layout]);

  var navigate = useNavigate();
  if (!authObject.user) {
    navigate("/");
  }

  useEffect(() => {}, []);

  return (
    <div
      className="userpage p-3 mx-auto"
      style={{
        backgroundColor: getLayout(layout, 12, 0),
        color: getLayout(layout, 12, 1),
      }}
    >
      <div className="w-100">
        <button className="btn btn-secondary" onClick={(e) => navigate("/")}>
          keer terug
        </button>
      </div>
      <div className="h1 text-center mt-2 inherittext">Uw gegevens</div>
      <UserBox />
      <div className="mt-5">
        <h3 className="h3 text-center inherittext">Huidig verzendadres</h3>
        <div className="userbuttongroup d-flex justify-content-end">
          {!shipToggler && (
            <>
              <button
                className="btn btn-primary proceedbutton"
                onClick={(e) => setShipToggler(true)}
              >
                Kies ander
              </button>
            </>
          )}
        </div>
        {/* ------------- Defaultshipping Address ------------- */}
        {!shipToggler &&
          authObject.addresslist
            .filter((add) => add.defaultshipping == true)
            .map((add, index) => {
              return (
                <div key={index}>
                  <AddressBox add={add} editdel={true} create={false} />
                </div>
              );
            })}
        {shipToggler && (
          <Addresslist
            changebilling={false}
            toggler={shipToggler}
            setToggler={setShipToggler}
          />
        )}
        <h3 className="mt-5 text-center inherittext">Huidig factuuradres</h3>
        <div className="userbuttongroup d-flex justify-content-end">
          {!billToggler && (
            <>
              <button
                className="btn btn-primary proceedbutton"
                onClick={(e) => setBillToggler(true)}
              >
                Kies ander
              </button>
            </>
          )}
        </div>
        {/* ------------- Defaultbilling Address ------------- */}
        {!billToggler &&
          authObject.addresslist
            .filter((add) => add.defaultbilling == true)
            .map((add, index) => {
              return (
                <div key={index}>
                  <AddressBox add={add} editdel={true} create={false} />
                </div>
              );
            })}
        {billToggler && (
          <Addresslist
            changebilling={true}
            toggler={billToggler}
            setToggler={setBillToggler}
          />
        )}
        {/* ------------- Other Address ------------- */}
        <div>
          <h3 className="text-center mt-5 inherittext">Andere addressen</h3>
          <div className="userbuttongroup d-flex justify-content-end">
            <button
              className="btn btn-primary proceedbutton"
              onClick={(e) => setCreateAdToggler(true)}
            >
              Toevoegen
            </button>
          </div>

          {createAdToggler && (
            <AddressBox
              add={newAd}
              editdel={false}
              create={true}
              createToggler={createAdToggler}
              setCreateToggler={setCreateAdToggler}
            />
          )}

          {authObject.addresslist
            .filter(
              (add) =>
                add.defaultshipping == false && add.defaultbilling == false
            )
            .map((add, index) => {
              return (
                <div key={index}>
                  <AddressBox add={add} editdel={true} create={false} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default UserPage;
