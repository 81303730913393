import React from "react";

const LocBlock = ({ loc }) => {
  return (
    <div className="text-center w-100 mt-2 bg-secondary mx-auto text-white rounded-top">
      <div>{loc.name}</div>
      <div>
        {loc.street} {loc.hnr} {loc.hnrext}
      </div>
      <div>
        {loc.zip} {loc.city}{" "}
      </div>
      <div>{loc.country}</div>
    </div>
  );
};

export default LocBlock;
