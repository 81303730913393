import React from "react";

const DetailVW = ({ vw }) => {
  return (
    <div className="mt-5 bg-white p-3 w-90 mx-auto text-black">
      <div className="h3 text-black">Voedingswaarden</div>
      <table className="table table-sm">
        <thead>
          <tr>
            <th></th>
            <th>per 100g/100 ml</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="fw-bold">Energie (kJ/kcal)</td>
            <td>{vw.energie}</td>
          </tr>
          <tr>
            <td className="fw-bold">Vetten (g)</td>
            <td>{vw.vetten}</td>
          </tr>
          <tr>
            <td className="fw-bold">Waarvan verzadigde vetzuren (kJ/kcal)</td>
            <td>{vw.vetten_verzadigd}</td>
          </tr>
          <tr>
            <td className="">
              <span className="ms-2">
                waarvan enkelvoudig onverzadigde vetzuren(g)
              </span>
            </td>
            <td>{vw.vetten_verz_enkelv}</td>
          </tr>
          <tr className="">
            <td className="">
              <span className="ms-2">
                waarvan meervoudig onverzadigde vetzuren(g)
              </span>
            </td>
            <td>{vw.vetten_verz_meerv}</td>
          </tr>
          <tr>
            <td className="fw-bold">Koolhydraten (g)</td>
            <td>{vw.koolhydraten}</td>
          </tr>
          <tr>
            <td className="fw-bold">
              <span className="ms-2">waarvan suikers (g)</span>
            </td>
            <td>{vw.kool_suikers}</td>
          </tr>
          <tr>
            <td className="">
              <span className="ms-2">waarvan polyolen (g)</span>
            </td>
            <td>{vw.kool_polyolen}</td>
          </tr>
          <tr>
            <td className="">
              <span className="ms-2">waarvan zetmeel (g)</span>
            </td>
            <td>{vw.kool_zetmeel}</td>
          </tr>
          <tr>
            <td className="fw-bold">Vezels (g)</td>
            <td>{vw.vezels}</td>
          </tr>
          <tr>
            <td className="fw-bold">Eiwitten (g)</td>
            <td>{vw.eiwitten}</td>
          </tr>
          <tr>
            <td className="fw-bold">Zout (g)</td>
            <td>{vw.zout}</td>
          </tr>
          <tr>
            <td className="">Vitaminen en mineralen</td>
            <td>{vw.vitamins}</td>
          </tr>
          <tr>
            <td className="fw-bold">Opmerkingen</td>
            <td>{vw.remarks}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DetailVW;
