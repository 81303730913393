import React, { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Store/auth-context";

const ShopContext = createContext({
  openshop: [],
  setopenshop: () => {},
  checkout: [],
  setcheckout: () => {},

  shopreset: [],
  setshopreset: () => {},
  cart: [],
  setcart: () => {},
  cartoptions: [],
  setcartoptions: () => {},
  showcart: [],
  setshowcart: () => {},
  shipmenttype: null,
  setshipmenttype: () => {},
  booking: null,
  setbooking: () => {},
  deldate: null,
  setdeldate: () => {},

  category: [],
  loadcategory: () => {},
  subcategory: [],
  setsubcategory: () => {},
  loadsubcategory: () => {},
  workcat: [],
  setworkcat: () => {},
  worksubcat: [],
  setworksubcat: () => {},

  products: [],
  setproducts: () => {},
  productoptions: [],
  setproductoptions: () => {},
  productsettings: [],
  shopoptions: [],
  // getallproducts: () => {},
  getcatproducts: () => {},
  getpromoproducts: () => {},
  getfavproducts: () => {},
  getcatproductoptions: () => {},
  getpromoproductoptions: () => {},
  getfavproductoptions: () => {},

  nopromoproducts: false,
  loadcart: () => {},
  loadcartoptions: () => {},
  cartitemcreate: () => {},
  cartitemcreatewoptions: () => {},
  cartitemupdate: () => {},
  cartitemdelete: () => {},
  ordercreate: () => {},
  orderconfirmpayment: () => {},
  spinner: [],
  setspinner: () => {},
});

export function ShopContextProvider(props) {
  const authObject = useContext(AuthContext);

  const [openShop, setOpenShop] = useState(false);
  const [checkOut, setCheckOut] = useState(false);

  const [shopOptions, setShopOptions] = useState([]);
  const [shopReset, setShopReset] = useState(false);
  const [cart, setCart] = useState([]);
  const [cartOptions, setCartOptions] = useState([]);

  const [category, setCategory] = useState([]);
  const [workCat, setWorkCat] = useState(null);
  const [subCategory, setSubCategory] = useState([]);
  const [workSubCat, setWorkSubCat] = useState(null);
  const [products, setProducts] = useState([]);
  const [productSettings, setProductSettings] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [shipType, setShipType] = useState(null);
  const [booking, setBooking] = useState([]);
  const [delDate, setDelDate] = useState(new Date());
  const [showCart, setShowCart] = useState(false);

  const [noPromoProducts, setNoPromoProducts] = useState(false);
  const [spinner, setSpinner] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    getAllProductSettings();
    loadCategory();
    loadSubCategory();
    getShopOptions();
  }, []);

  useEffect(() => {
    if (authObject.shopsessiontoken === null) {
      setCart([]);
    }
    loadCart();
    getAllProductSettings();
    loadCategory();
    loadSubCategory();
    getShopOptions();
  }, [authObject.shopsessiontoken]);

  const loadCart = async () => {
    var url = process.env.REACT_APP_API_URL + "api/cart-list/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shopsessiontoken: authObject.shopsessiontoken,
        webshop: authObject.webshoptoken,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setCart(resp);
        loadCartOptions();
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const loadCartOptions = async () => {
    var url = process.env.REACT_APP_API_URL + "api/cart-options/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shopsessiontoken: authObject.shopsessiontoken,
        webshop: authObject.webshoptoken,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setCartOptions(resp);
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const getCatProducts = async (cat) => {
    var url = process.env.REACT_APP_API_URL + "api/product-list/cat/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        webshop: authObject.webshoptoken,
        category: cat.id,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setProducts(resp);
        setSpinner(false);
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const getCatProductOptions = async (cat) => {
    var url = process.env.REACT_APP_API_URL + "api/product-options/cat/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        webshop: authObject.webshoptoken,
        category: cat.id,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setProductOptions(resp);
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const getFavProducts = async (userid) => {
    var url = process.env.REACT_APP_API_URL + "api/product-list/fav/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        webshop: authObject.webshoptoken,
        userid: userid,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setProducts(resp);
        setSpinner(false);
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const getFavProductOptions = async (userid) => {
    var url = process.env.REACT_APP_API_URL + "api/product-options/fav/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        webshop: authObject.webshoptoken,
        userid: userid,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setProductOptions(resp);
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const getPromoProducts = async () => {
    var url = process.env.REACT_APP_API_URL + "api/product-list/promo/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ webshop: authObject.webshoptoken }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.length > 0) {
          setProducts(resp);
          setNoPromoProducts(false);
        } else {
          setNoPromoProducts(true);
        }
        setSpinner(false);
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const getPromoProductOptions = async () => {
    var url = process.env.REACT_APP_API_URL + "api/product-options/promo/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ webshop: authObject.webshoptoken }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setProductOptions(resp);
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const getAllProductSettings = async () => {
    var url = process.env.REACT_APP_API_URL + "api/product/settings/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ webshop: authObject.webshoptoken }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setProductSettings(resp);
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const loadCategory = async () => {
    var url = process.env.REACT_APP_API_URL + "api/category-list/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ webshop: authObject.webshoptoken }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setCategory(resp);
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const loadSubCategory = async () => {
    var url = process.env.REACT_APP_API_URL + "api/subcategory-list/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ webshop: authObject.webshoptoken }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setSubCategory(resp);
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const cartItemCreate = async (data) => {
    data.webshop = authObject.webshoptoken;
    var url = process.env.REACT_APP_API_URL + "api/cartitem/create/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((resp) => {
        if (resp.ok) {
          setShopReset(!shopReset);
          loadCart();
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const cartItemCreateWOptions = async (item, options) => {
    var url = process.env.REACT_APP_API_URL + "api/cartitem/createwoptions/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ item: item, options: options }),
    })
      .then((resp) => {
        if (resp.ok) {
          setShopReset(!shopReset);
          loadCart();
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const cartItemUpdate = async (item) => {
    var url = `${process.env.REACT_APP_API_URL}api/cartitem/update/${item.id}/`;
    await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    })
      .then((resp) => {
        if (resp.ok) {
          setShopReset(!shopReset);
          loadCart();
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const cartItemDelete = async (itemid) => {
    var url = `${process.env.REACT_APP_API_URL}api/cartitem/delete/${itemid}/`;
    await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => {
        if (resp.ok) {
          setShopReset(!shopReset);
          loadCart();
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const orderCreate = async (shopsessiontoken, remarks) => {
    var url = process.env.REACT_APP_API_URL + "api/order/create/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + localStorage.getItem("access"),
      },
      body: JSON.stringify({
        shopsessiontoken: shopsessiontoken,
        webshop: authObject.webshoptoken,
        userid: authObject.user.id,
        shipaddress: authObject.shipaddress,
        billaddress: authObject.billaddress,
        shipmenttype: shipType,
        deldate: delDate,
        remarks: remarks,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          navigate("/paypage/");
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const orderConfirmPayment = async (pm) => {
    var url = process.env.REACT_APP_API_URL + "api/order/confirmpayment/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + localStorage.getItem("access"),
      },
      body: JSON.stringify({
        shopsessiontoken: authObject.shopsessiontoken,
        webshop: authObject.webshoptoken,
        userid: authObject.user.id,
        paymethod: pm,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const getShopOptions = async () => {
    var url = process.env.REACT_APP_API_URL + "api/shopoptions/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        webshop: authObject.webshoptoken,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setShopOptions(resp);
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const context = {
    openshop: openShop,
    setopenshop: setOpenShop,
    checkout: checkOut,
    setcheckout: setCheckOut,

    shopreset: shopReset,
    setshopreset: setShopReset,

    cart: cart,
    setcart: setCart,

    cartoptions: cartOptions,
    setcartoptions: setCartOptions,

    showcart: showCart,
    setshowcart: setShowCart,

    shipmenttype: shipType,
    setshipmenttype: setShipType,

    booking: booking,
    setbooking: setBooking,

    deldate: delDate,
    setdeldate: setDelDate,

    category: category,
    loadcategory: loadCategory,
    subcategory: subCategory,
    setsubcategory: setSubCategory,
    loadsubcategory: loadSubCategory,

    workcat: workCat,
    setworkcat: setWorkCat,
    worksubcat: workSubCat,
    setworksubcat: setWorkSubCat,

    products: products,
    setproducts: setProducts,
    // getallproducts: getAllProducts,
    getpromoproducts: getPromoProducts,
    getcatproducts: getCatProducts,
    getfavproducts: getFavProducts,

    getpromoproductoptions: getPromoProductOptions,
    getcatproductoptions: getCatProductOptions,
    getfavproductoptions: getFavProductOptions,
    nopromoproducts: noPromoProducts,

    productsettings: productSettings,

    productoptions: productOptions,
    setproductoptions: setProductOptions,

    shopoptions: shopOptions,

    loadcart: loadCart,
    loadcartoptions: loadCartOptions,

    cartitemcreate: cartItemCreate,
    cartitemcreatewoptions: cartItemCreateWOptions,
    cartitemupdate: cartItemUpdate,
    cartitemdelete: cartItemDelete,
    ordercreate: orderCreate,
    orderconfirmpayment: orderConfirmPayment,
    spinner: spinner,
    setspinner: setSpinner,
  };

  return (
    <ShopContext.Provider value={context}>
      {props.children}
    </ShopContext.Provider>
  );
}

export default ShopContext;
