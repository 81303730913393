import React, { useContext } from "react";
import AuthContext from "../Store/auth-context";
import ShopContext from "../Store/shop-context";
import GetCountry from "./GetCountry";
import GetTitle from "./GetTitle";

const Addresslist = ({ changebilling, toggler, setToggler }) => {
  const authObject = useContext(AuthContext);
  const shopObject = useContext(ShopContext);
  const addList = authObject.addresslist;

  const updateAddress = async (address) => {
    var url = `${process.env.REACT_APP_API_URL}api/address/update/${address.id}/`;
    await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + localStorage.getItem("access"),
      },
      body: JSON.stringify(address),
    })
      .then((resp) => {
        if (resp.ok) {
          console.log("addressupdateresponse ok");
          authObject.getaddresslist(authObject.user.id);
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const selectAddress = (addid) => {
    if (changebilling === true) {
      var oldselection = addList.filter(
        (add) => add.defaultbilling === true
      )[0];
    } else {
      var oldselection = addList.filter(
        (add) => add.defaultshipping === true
      )[0];
    }
    var newselection = addList.filter((add) => add.id === addid)[0];

    if (changebilling === true) {
      if (oldselection) {
        oldselection.defaultbilling = false;
        updateAddress(oldselection);
      }
      if (newselection) {
        newselection.defaultbilling = true;
        updateAddress(newselection);
        authObject.setbilladdress({
          ...newselection,
          ["title"]: authObject.user.title,
          ["firstname"]: authObject.user.firstname,
          ["name"]: authObject.user.name,
        });
      }
      setToggler(!toggler);
    } else {
      if (oldselection) {
        oldselection.defaultshipping = false;
        updateAddress(oldselection);
      }
      if (newselection) {
        newselection.defaultshipping = true;
        updateAddress(newselection);
        authObject.setshipaddress(newselection);
      }
      setToggler(!toggler);
    }
    oldselection = null;
    newselection = null;
  };

  return (
    <div className="addressbox py-3 px-1">
      <div className="">
        <button
          className="btn btn-secondary btn-sm"
          onClick={() => setToggler(!toggler)}
        >
          Annuleer
        </button>
      </div>
      <div className="mx-auto text-center mb-2">Uw adressen</div>
      <div className="fs-italic text-center mb-2">
        Klik op het adress naar keuze
      </div>
      {authObject.addresslist &&
        authObject.addresslist.map((add, index) => {
          return (
            <div key={index} className="w-75 text-center mx-auto">
              <button
                className="d-flex flex-column mb-2 bg-secondary text-white border-none text-center w-100 justify-content-center"
                onClick={(e) => selectAddress(add.id)}
              >
                <div className="mx-auto">
                  <GetTitle code={add.title} /> {add.firstname} {add.name}
                </div>
                <div className="mx-auto">
                  {add.street} {add.hnr} {add.hnrext}
                </div>
                <div className="mx-auto">
                  {add.zip} {add.city}
                </div>
                <div className="mx-auto">
                  <GetCountry code={add.country} />
                </div>
              </button>
            </div>
          );
        })}
    </div>
  );
};

export default Addresslist;
