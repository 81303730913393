import React, { useContext } from "react";
import AuthContext from "../Store/auth-context";
import ShopContext from "../Store/shop-context";

const CartTotal = () => {
  const shopObject = useContext(ShopContext);

  const cart = shopObject.cart;

  var totalprice = 0;
  var totalvat = 0;

  cart.map((c) => {
    let ctotal =
      (c.cartitemprice * (1 + c.cartitemvat)).toFixed(2) * c.quantity;
    let ctotalwovat = c.cartitemprice.toFixed(2) * c.quantity;
    totalprice = totalprice + ctotal;
    totalvat = totalvat + (ctotal - ctotalwovat);
  });

  return (
    <>
      <div className="totalcartbox fs-6">
        <div className="totalcartcontent">
          <div className="totalcartline">
            <div className="totalcartlineleft">Totaal (incl BTW)</div>
            <div className="totalcartlineright">
              {totalprice.toFixed(2)} Eur
            </div>
          </div>
          <div className="totalcartline">
            <div className="totalcartlineleft">BTW winkelmandje</div>
            <div className="totlacartlineright">{totalvat.toFixed(2)} Eur</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartTotal;
