import React, { useState, useContext } from "react";
import { isocountries, titles, blankaddress } from "../Data/data";
import AuthContext from "../Store/auth-context";

const AddressInit = () => {
  const [ad, setAd] = useState(blankaddress);
  const authObject = useContext(AuthContext);

  const createAddress = async (data) => {
    var url = `${process.env.REACT_APP_API_URL}api/address/create/`;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + localStorage.getItem("access"),
      },
      body: JSON.stringify(data),
    })
      .then((resp) => {
        if (resp.ok) {
          console.log("address create ok");
          authObject.getaddresslist(authObject.user.id);
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const handleCreateAddress = (e) => {
    e.preventDefault();
    ad.user = authObject.user.id;
    ad.defaultshipping = true;
    ad.defaultbilling = true;
    setAd(ad);
    console.log("Init Address added :", ad);
    createAddress(ad);
  };

  function handleChangeAddress(e) {
    const name = e.target.name;
    const value = e.target.value;
    setAd({ ...ad, [name]: value });
  }

  return (
    <>
      <h2 className="p-1">
        Om bestellingen te plaatsen dient voorafgaand ten minste één adres te
        worden ingevuld.
      </h2>
      <form className="p-1" onSubmit={(e) => handleCreateAddress(e)}>
        <div className="row g-3 mb-3">
          <div className="col-md-6">
            <label className="form-label">Straatnaam</label>
            <input
              className="form-control col"
              placeholder="straatnaam"
              type="text"
              required
              id="street"
              name="street"
              value={ad.street}
              onInput={handleChangeAddress}
              pattern="[a-zA-Zéèçà()\s\-^<>]{1,200}$"
            />
          </div>
          <div className="col md-3">
            <label className="form-label">Huisnummer</label>
            <input
              className="form-control col"
              placeholder="huisnummer"
              type="text"
              size="3"
              required
              id="hnr"
              name="hnr"
              value={ad.hnr}
              onInput={handleChangeAddress}
              pattern="[0-9a-zA-Z]{1,10}$"
            />
          </div>
          <div className="col md-3">
            <label className="form-label">Extensie</label>
            <input
              className="form-control col"
              placeholder="nummer extensie"
              type="text"
              id="hnrext"
              name="hnrext"
              value={ad.hnrext}
              onInput={handleChangeAddress}
              pattern="[0-9a-zA-Z]{1,10}$"
            />
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div className="col md-3">
            <label className="form-label">Postcode</label>
            <input
              className="form-control"
              placeholder="postcode"
              type="text"
              required
              id="zip"
              name="zip"
              value={ad.zip}
              onInput={handleChangeAddress}
              pattern="[0-9]{4,20}$"
            />
          </div>
          <div className="col md-6">
            <label className="form-label">Stad</label>
            <input
              className="form-control"
              placeholder="stad"
              type="text"
              required
              id="city"
              name="city"
              value={ad.city}
              onInput={handleChangeAddress}
              pattern="[a-zA-Zéèçà()\s\-^<>]{1,100}$"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col md-6">
            <label className="form-label">Land</label>
            <select
              className="form-control"
              placeholder="Land"
              type="text"
              required
              id="country"
              name="country"
              value={ad.country}
              onInput={handleChangeAddress}
              default="BE"
            >
              <option name="country" value="">
                -- kies een land --
              </option>
              {isocountries &&
                isocountries.map((ic, index) => {
                  return (
                    <option name="country" value={ic.code} key={index}>
                      {ic.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col md-6"></div>
        </div>
        <div className="form-group mt-3">
          <div></div>
          <button className="btn btn-primary" type="submit">
            Adres opslaan
          </button>
        </div>
      </form>
    </>
  );
};

export default AddressInit;
