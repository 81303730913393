import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import AuthContext from "../Store/auth-context";
import useLayout from "../Functions/useLayout";

function LoginAfterSignupPage() {
  let { loginuser, errormessage, seterrormessage } = useContext(AuthContext);

  const [formData, setFormData] = useState({ email: "", password: "" });
  const { email, password } = formData;
  const [passwordType, setPasswordType] = useState("password");

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    seterrormessage("");
    var lowermail = email.toLowerCase();
    loginuser(lowermail, password);
  };

  const pwToggler = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div
      className="container my-5 py-2"
      style={{ backgroundColor: useLayout(2, 0), color: useLayout(2, 1) }}
    >
      <h1>Log in</h1>
      <p>Je hebt net geregistreerd.</p>
      <div className="fs-3 fw-bold p-2">
        Vooraleer je kan inloggen, dien je eerst je email te bevestigen.
      </div>
      <div>
        Je hebt hiervoor een activatieknop ontvangen van mailer@ubicopia.be.
      </div>
      <div>
        Dit is een email van de webwinkel-server, en kan je vertrouwen, dit is
        geen spam.
      </div>
      <p>Deze knop dien je aan te klikken.</p>
      <p>Vervolgens kan je hieronder inloggen.</p>
      <p className="fs-3 fst-italic">
        !! Geen email ? Check ook even je spam-folder !!
      </p>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <input
            className="form-control"
            type="email"
            placeholder="email"
            name="email"
            value={email}
            onChange={(e) => onChange(e)}
            required
          />
        </div>
        <div className="form-group mt-3">
          <div className="form-control d-flex justify-content-between">
            <input
              className="bg-transparent border-0 w-75"
              type={passwordType}
              placeholder="password"
              name="password"
              value={password}
              onChange={(e) => onChange(e)}
              minLength="6"
              required
            />
            <button
              type="button"
              className="bg-transparent border-0"
              onClick={() => pwToggler()}
            >
              {passwordType === "password" ? (
                <i className="bi bi-eye fs-5"></i>
              ) : (
                <i className="bi bi-eye-slash fs-5"></i>
              )}
            </button>
          </div>
        </div>
        {errormessage !== "" && (
          <>
            <div>
              <div>
                <strong>{errormessage}</strong>
              </div>
              <div>
                Om je emailadres te bevestigen heb je een email ontvangen van
                mailer@ubicopia.com.
              </div>
              <div>
                Dit is een veilig email adres van onze webwinkel en dus geen
                spam of phishing.
              </div>
              <div>
                Desondanks onze inspanningen is het mogelijk dat deze in je
                spamfolder is beland.
              </div>
              <div>
                Blijf je problemen ondervinden, stuur een email naar{" "}
                <a href={`mailto:info@ubicopia.com`} className="ms-3 link-info">
                  info@ubicopia.com
                </a>
              </div>
            </div>
          </>
        )}

        <div className="form-group mt-3">
          <button className="btn btn-primary w-50" type="submit">
            Log in
          </button>
          <div className="mt-5">
            <span className="mt-5 w-25">Ben je je paswoord vergeten ? </span>
            <Link to="/reset-password/">
              <button className="btn btn-primary btn-sm">
                Herstel Paswoord
              </button>
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
}

export default LoginAfterSignupPage;
