import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Store/auth-context";
import ShopContext from "../Store/shop-context";
import DisplayDatefromPython from "../Components/DisplayDatefromPython";
import getLayout from "../Functions/getLayout";

import { blankOrder } from "../Data/data";

const PaymentPage = () => {
  const authObject = useContext(AuthContext);
  const shopObject = useContext(ShopContext);

  const [paymentToggler, setPaymentToggler] = useState(null);
  const [cardPayToggler, setCardPayToggler] = useState(false);
  const [onlinePays, setOnlinePays] = useState(0);

  let navigate = useNavigate();

  // for card payment
  const [extUrl, setExtUrl] = useState(null);

  // for transfer payment
  const [organisation, setOrganisation] = useState(authObject.organisation);
  const [order, setOrder] = useState(blankOrder);
  const [booking, setBooking] = useState(shopObject.booking);
  const [layout, setLayout] = useState();

  useEffect(() => {
    if (shopObject.shopoptions.layout) {
      setLayout(JSON.parse(shopObject.shopoptions.layout));
    }
  }, [shopObject.shopoptions.layout]);

  if (!authObject.accesstoken) {
    navigate("/");
  }

  function createPayment() {
    var url = process.env.REACT_APP_API_URL + "api/payment/create/";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        shopsessiontoken: authObject.shopsessiontoken,
        userid: authObject.user.id,
        webshop: authObject.webshoptoken,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setExtUrl(resp.RedirectUrl);
      })
      .then((error) => (error ? console.log("error :", error) : null));
  }

  function checkPayment() {
    var url = process.env.REACT_APP_API_URL + "api/payment/check/";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        shopsessiontoken: authObject.shopsessiontoken,
        userid: authObject.user.id,
        webshop: authObject.webshoptoken,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status == "captured") {
          setExtUrl(null);
          confirmOnlinePayment();
        }
        if (resp.status == "aborted") {
          setExtUrl(null);
          setPaymentToggler(null);
          navigate("/abortpage/");
        }
        if (resp.status == "failed") {
          setExtUrl(null);
          setPaymentToggler(null);
          navigate("/failpage/");
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  }

  function getOnlineOrders() {
    var url = process.env.REACT_APP_API_URL + "api/order/checkonlinepayments/";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        userid: authObject.user.id,
        webshop: authObject.webshoptoken,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setOnlinePays(resp);
      });
  }

  function getOrganisationDetails() {
    var url = process.env.REACT_APP_API_URL + "api/organisation/details/";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        webshop: authObject.webshoptoken,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => setOrganisation(resp));
  }

  function getOrderDetails() {
    var url = process.env.REACT_APP_API_URL + "api/order/details/";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        userid: authObject.user.id,
        webshop: authObject.webshoptoken,
        shopsessiontoken: authObject.shopsessiontoken,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setOrder(resp);
      });
  }

  function getBookingDetails(bid) {
    var url = process.env.REACT_APP_API_URL + "api/booking/details/";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        userid: authObject.user.id,
        webshop: authObject.webshoptoken,
        shopsessiontoken: authObject.shopsessiontoken,
        bookingid: bid,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setBooking(resp);
      });
  }

  function startPayment() {
    createPayment();
  }
  function cancelPayment() {
    setPaymentToggler(null);
  }

  function confirmOnlinePayment() {
    let pm = "O";
    shopObject.orderconfirmpayment(pm);
    navigate("/successpage/");
    authObject.createshopsession();
  }

  function confirmTransferPayment() {
    let pm = "T";
    shopObject.orderconfirmpayment(pm);
    navigate("/Successpage/");
    authObject.createshopsession();
  }

  function confirmPOSPayment() {
    let pm = "P";
    shopObject.orderconfirmpayment(pm);
    navigate("/successpage/");
    authObject.createshopsession();
  }

  useEffect(() => {
    let fivesec = 1000 * 5;
    let interval = setInterval(() => {
      if (extUrl !== null) {
        checkPayment();
      }
    }, fivesec);
    return () => clearInterval(interval);
  }, [extUrl]);

  useEffect(() => {
    getOrderDetails();
    getOrganisationDetails();
    getOnlineOrders();
  }, []);

  useEffect(() => {
    if (order.shipmethod == "P") {
      getBookingDetails(order.booking);
    }
  }, [order]);

  return (
    <div
      className="paymentpage"
      style={{
        backgroundColor: getLayout(layout, 13, 0),
        color: getLayout(layout, 13, 1),
      }}
    >
      <div className="h1 paymenttitle mt-5">Afrekenen</div>
      {paymentToggler === null && (
        <>
          <div className="paymentsubtitle"> Bedankt voor uw bestelling</div>
          <div className="paymentsubtitle">
            {" "}
            Gelieve een betaalwijze te kiezen
          </div>
          <div className="paymentoptiongroup">
            {shopObject.shopoptions.en_onlinepayment && (
              <>
                <div className="d-flex flex-column justify-content-center flex-md-row ">
                  <button
                    className="btn btn-primary paymentbutton mx-auto"
                    onClick={(e) => {
                      startPayment();
                      setPaymentToggler("card");
                    }}
                  >
                    Online betalen
                  </button>
                  <div className="paymentdescription mx-auto">
                    Werk met meeste kaarten en online betalingen.
                  </div>
                </div>
              </>
            )}
            {shopObject.shopoptions.minonlineorders > onlinePays.length && (
              <>
                <div className="paymentoption">
                  {" "}
                  Online betalingen verplicht bij de eerste{" "}
                  {shopObject.shopoptions.minonlineorders} bestellingen.
                </div>
              </>
            )}
            {shopObject.shopoptions.minonlineorders <= onlinePays.length &&
              shopObject.shopoptions.en_transferpayment && (
                <>
                  <div className="d-flex flex-column flex-md-row justify-content-center">
                    <button
                      className="btn btn-primary paymentbutton mx-auto"
                      onClick={(e) => {
                        setPaymentToggler("transfer");
                        getOrganisationDetails();
                      }}
                    >
                      Overschrijving
                    </button>
                    <div className="paymentdescription mx-auto">
                      Dit neemt enige tijd in beslag en kan uw levering
                      vertragen.
                    </div>
                  </div>
                </>
              )}
            {shopObject.shopoptions.minonlineorders <= onlinePays.length &&
              shopObject.shopoptions.en_pickuppayment && (
                <>
                  {shopObject.shipmenttype === "P" && (
                    <div className="d-flex flex-column flex-md-row justify-content-center my-4">
                      <button
                        className="btn btn-primary paymentbutton mx-auto"
                        onClick={(e) => setPaymentToggler("pos")}
                      >
                        Betalen bij afhalen
                      </button>
                      <div className="paymentdescription mx-auto">
                        U betaalt wanneer u de goederen afhaalt.
                      </div>
                    </div>
                  )}
                </>
              )}
          </div>
        </>
      )}
      {paymentToggler === "card" && (
        <div className="paycardbox mx-auto">
          <div className="row d-flex justify-content-center">
            {extUrl && (
              <iframe
                className="paycardexternal"
                src={extUrl}
                title="Payment window"
              ></iframe>
            )}
          </div>
          {/* ---------- Buttons ------------- */}
          <div className="checkoutbuttongroup mt-1">
            <button
              className="btn btn-secondary btn-sm gobackbutton"
              onClick={(e) => setPaymentToggler(null)}
            >
              Terug
            </button>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => cancelPayment()}
            >
              Annuleer betaling
            </button>
            {/* <button
              className="btn btn-primary btn-sm proceedbutton"
              onClick={(e) => confirmOnlinePayment()}
            >
              Ga verder
            </button> */}
          </div>
        </div>
      )}
      {paymentToggler === "transfer" && (
        <div className="transferbox">
          <div className="transferboxtitle">
            Gelieve over te schrijven naar :
          </div>
          <div className="transferline">
            <div className="transferlineleft">Naam</div>
            <div className="transferlineright">{organisation.name}</div>
          </div>

          <div className="transferline">
            <div className="transferlineleft">IBAN</div>
            <div className="transferlineright">
              {organisation.bankiban.substr(0, 4)}{" "}
              {organisation.bankiban.substr(3, 4)}{" "}
              {organisation.bankiban.substr(7, 4)}{" "}
              {organisation.bankiban.substr(11, 4)}{" "}
            </div>
          </div>
          <div className="transferline">
            <div className="transferlineleft">BIC</div>
            <div className="transferlineright">{organisation.bankbic}</div>
          </div>
          <div className="transferline">
            <div className="transferlineleft">Bedrag</div>
            <div className="transferlineright">
              {(order.totalprice + order.totalvat).toFixed(2)}
            </div>
          </div>
          <div className="transferline">
            <div className="transferlineleft">Referentie</div>
            <div className="transferlineright">{order.ref}</div>
          </div>
          {/* ---------- Buttons ------------- */}
          <div className="checkoutbuttongroup">
            <button
              className="btn btn-secondary gobackbutton"
              onClick={(e) => setPaymentToggler(null)}
            >
              Terug
            </button>
            <button
              className="btn btn-primary proceedbutton"
              onClick={(e) => confirmTransferPayment()}
            >
              Ga verder
            </button>
          </div>
        </div>
      )}
      {paymentToggler === "pos" && booking && order && (
        <div className="POSbox">
          <div className="POSboxtitle">
            U hebt gekozen voor betalen bij ophalen
          </div>
          <div className="POSline"></div>
          <div className="POSlineleft"></div>
          <div className="POSlineright"></div>
          <div className="POSline">
            <div className="POSlineleft">Op datum</div>
            <div className="POSlineright">
              <DisplayDatefromPython date={booking.date} />
            </div>
          </div>
          <div className="POSline">
            <div className="POSlineleft">Locatie</div>
            <div className="POSlineright">{booking.location}</div>
          </div>
          <div className="POSline">
            <div className="POSlineleft">tijdslot</div>
            <div className="POSlineright">
              {booking.slotfrom.slice(0, 5)}-{booking.slotto.slice(0, 5)}
            </div>
          </div>

          {/* ---------- Buttons ------------- */}
          <div className="checkoutbuttongroup">
            <button
              className="btn btn-secondary gobackbutton"
              onClick={(e) => setPaymentToggler(null)}
            >
              Terug
            </button>
            <button
              className="btn btn-primary proceedbutton"
              onClick={(e) => confirmPOSPayment()}
            >
              Ga verder
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
