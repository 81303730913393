import React, { useState, useContext } from "react";
import AuthContext from "../Store/auth-context";
import { titles } from "../Data/data";
import GetTitle from "./GetTitle";

const ShowUserBox = () => {
  const authObject = useContext(AuthContext);
  const [user, setUser] = useState(authObject.user);

  const [clickedEdit, setClickedEdit] = useState(false);

  const updateUserDetails = async (user) => {
    user.webshop = authObject.webshoptoken;
    var url = `${process.env.REACT_APP_API_URL}api/userdetails/update/`;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + localStorage.getItem("access"),
      },
      body: JSON.stringify(user),
    })
      .then((resp) => {
        if (resp.ok) {
          console.log("details updated");
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  function clickEdit() {
    setClickedEdit(true);
  }
  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setUser({ ...user, [name]: value });
  }

  const handleUpdate = (e) => {
    e.preventDefault();
    updateUserDetails(user);
    setClickedEdit(false);
  };

  return (
    <div className="userbox py-3">
      <div className="h3 userboxtitle text-center">Uw data</div>
      <div className="row ms-3">
        <div className="col-6">Naam</div>
        <div className="col-6">
          <GetTitle code={user.title} /> {user.firstname} {user.name}
        </div>
      </div>
      <div className="row ms-3">
        <div className="col-6">Email</div>
        <div className="col-6">{user.email}</div>
      </div>
      <div className="row ms-3">
        <div className="col-6">Tel.</div>
        <div className="col-6">{user.phone}</div>
      </div>
    </div>
  );
};

export default ShowUserBox;
