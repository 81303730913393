import React from "react";
import fillZero from "./fillZero";
import getNextDay from "./getNextDay";
import getNextWeekDay from "./getNextWeekDay";
import getNextDeliveryDay from "./getNextDeliveryDay";

function getdeltime(orderdelivery) {
  let orderdeliverytime =
    fillZero(orderdelivery.getHours(), 2) +
    ":" +
    fillZero(orderdelivery.getMinutes(), 2) +
    ":00";
  return orderdeliverytime;
}

function highestpushdatetime(list) {
  var hdate = new Date();
  var htime = "00:00";

  list.map((item) => {
    if (item.pushdate.getTime() > hdate.getTime()) {
      hdate = new Date(item.pushdate);
      htime = item.pushtime;
    }
  });
  var pusher = { day: hdate, time: htime };
  return pusher;
}

function finddeldaytime(list) {
  //finding soonest delivery per item that doesn't have a leadtime
  list.map((item) => {
    var soonest = { day: new Date("2100-12-31T00:00:00"), time: "23:59" }; // start from very far future
    item.settings.map((setting) => {
      if (setting.leadtime === null) {
        let deldate = getNextDeliveryDay(setting);
        console.log("deldate:", deldate);
        if (deldate.getTime() < soonest.day.getTime()) {
          soonest.day = deldate;
          soonest.day = new Date(soonest.day.setHours(0, 0, 0, 0));
          soonest.time = getdeltime(new Date(setting.orderdelivery));
        }
        item.pushdate = soonest.day;
        item.pushtime = soonest.time;
      }
    });
  });
  return list;
}

function defineleadtimes(list) {
  list.map((item) => {
    var day = new Date();
    var pushdate = new Date();
    item.settings.map((setting) => {
      if (setting.leadtime !== null) {
        var ldate = new Date(day.setDate(day.getDate() + setting.leadtime));
        if (ldate.getTime() > pushdate.getTime()) {
          pushdate = new Date(ldate.setHours(0, 0, 0, 0));
        }
      }
    });
    item.pushdate = pushdate;
  });
  return list;
}

function makelist(cart, crossList, allsettings) {
  var cartitemlist = [];
  cart.map((cartitem) => {
    if (crossList.filter((cl) => cl.id === cartitem.product).length > 0) {
      var item = { id: "", settings: [], pushdate: "", pushtime: "" };
      var settings = crossList.filter((cl) => cl.id === cartitem.product)[0]
        .settings;
      item.id = cartitem.product;
      item.pushdate = new Date();
      item.pushtime = "00:00";
      var itemsettings = [];
      settings.map((set) => {
        var set = allsettings.filter((as) => as.id === set)[0];
        itemsettings.push(set);
      });
      item.settings = itemsettings;
      cartitemlist.push(item);
    }
  });
  return cartitemlist;
}

const checkLeadtimes = (cart, crossList, allsettings) => {
  var pusher = { day: new Date(), time: "00:00" };

  // ------ itemlist met alle gegevens ------
  var itemlist = makelist(cart, crossList, allsettings);

  // ------ run itemlist voor leadtimes ------
  itemlist = defineleadtimes(itemlist);

  // ------ add pushdate and pushtime voor orderdeadlines ------
  itemlist = finddeldaytime(itemlist);

  //------ find highest pushdate ------
  pusher = highestpushdatetime(itemlist);

  //   // ------ check stepped leadtime ------
  //   var daytime = finddeldaytime(settingslist);

  // ------ test ------ and it works !!
  //   pusher.date = "2023-03-18";
  //   pusher.time = "09:00";

  return pusher;
};

export default checkLeadtimes;
