import React, { useContext } from "react";
import ShopContext from "../Store/shop-context";
import CartCounter from "./CartCounter";

const CartFollower = () => {
  const shopObject = useContext(ShopContext);
  return (
    <>
      {shopObject.cart.length > 0 && (
        <>
          <button
            className="cartfollower bg-primary text-white"
            onClick={() => shopObject.setshowcart(true)}
          >
            <CartCounter />
          </button>
        </>
      )}
    </>
  );
};

export default CartFollower;
