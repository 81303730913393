import React, { useState, useEffect, useContext } from "react";

import { useNavigate } from "react-router-dom";

import Calendar from "react-calendar";
import AuthContext from "../Store/auth-context";
import ShopContext from "../Store/shop-context";

import ShopCheckoutLocations from "../Components/ShopCheckoutLocations";
import ShopCheckoutShowAv from "../Components/ShopCheckoutShowAv";
import checkLeadtimes from "../Functions/checkLeadtimes";

const ShopCheckout = ({ toggler, setToggler }) => {
  const authObject = useContext(AuthContext);
  const shopObject = useContext(ShopContext);
  const webshop = authObject.webshoptoken;
  const [value, setValue] = useState(new Date());

  const [crossList, setCrossList] = useState([]);
  const [loc, setLoc] = useState([]);
  const [av, setAv] = useState([]);
  const [ts, setTs] = useState([]);

  let [leadTime, setLeadTime] = useState(0);
  let [timePusher, setTimePusher] = useState("00:00:00");
  let [datePusher, setDatePusher] = useState(
    shopObject.shopoptions.allowsameday
      ? new Date(new Date().setHours(0, 0, 0, 0))
      : new Date()
  );

  const [reset, setReset] = useState(false);

  var [avDates, setAvDates] = useState([]);
  var [avObjectDates, setAvObjectDates] = useState([]);
  var [unAvDates, setUnAvDates] = useState([]);
  var [unAvObjectDates, setUnAvObjectDates] = useState([]);

  const [selectLocation, setSelectLocation] = useState(0);
  const [clickAvDate, setClickAvDate] = useState(null);
  const [clickUnAvDate, setClickUnAvDate] = useState(null);
  const [clickRecurDate, setClickRecurDate] = useState(null);
  const [clickDate, setClickDate] = useState(new Date());

  const today = new Date();

  var navigate = useNavigate();

  useEffect(() => {
    getCrossList();
    getLocations();
    getAvailabilities();
    getTimeSlots();
  }, []);

  useEffect(() => {
    // checkProductSettings();
    if (crossList.length > 0) {
      var result = checkLeadtimes(
        shopObject.cart,
        crossList,
        shopObject.productsettings
      );
      setDatePusher(result.day);
      setTimePusher(result.time);
    }
  }, [crossList, loc && av && ts, reset]);

  useEffect(() => {
    convertUnAvDates();
    convertAvDates();
    filterUnavailabilities();
  }, [reset, selectLocation]);

  const getCrossList = async () => {
    let response = await fetch(
      process.env.REACT_APP_API_URL + "api/product-x-list/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshop: webshop,
        }),
      }
    );
    let data = await response.json();
    if (response.status === 200) {
      setCrossList(data);
    }
  };

  const getLocations = async () => {
    let response = await fetch(
      process.env.REACT_APP_API_URL + "api/pickuplocation/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshop: webshop,
        }),
      }
    );
    let data = await response.json();
    if (response.status === 200) {
      setLoc(data);
    }
  };

  const getAvailabilities = async () => {
    let response = await fetch(
      process.env.REACT_APP_API_URL + "api/availability/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshop: webshop,
        }),
      }
    );
    let data = await response.json();
    if (response.status === 200) {
      setAv(data);
      setReset(!reset);
    }
  };

  const getTimeSlots = async () => {
    let response = await fetch(
      process.env.REACT_APP_API_URL + "api/timeslot/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshop: webshop,
        }),
      }
    );
    let data = await response.json();
    if (response.status === 200) {
      setTs(data);
    }
  };

  function convertUnAvDates() {
    unAvDates = [];
    unAvObjectDates = [];
    if (av.length > 0) {
      if (selectLocation === 0) {
        // ---------- eerst de niet-recurring -------------
        av.filter((ava) => ava.unavailable === true).map((ava, index) => {
          let unava = new Date(ava.date).toDateString();
          let arrayObject = { date: unava, id: ava.id, loc: ava.location };
          unAvDates.push(unava);
          setUnAvDates(unAvDates);
          unAvObjectDates.push(arrayObject);
          setUnAvObjectDates(unAvObjectDates);
        });
      } else {
        // ---------- eerst de niet-recurring -------------
        av.filter(
          (ava) => ava.unavailable === true && ava.location === selectLocation
        ).map((ava, index) => {
          let unava = new Date(ava.date).toDateString();
          let arrayObject = { date: unava, id: ava.id, loc: ava.location };
          unAvDates.push(unava);
          setUnAvDates(unAvDates);
          unAvObjectDates.push(arrayObject);
          setUnAvDates(unAvDates);
        });
      }
    }
  }

  function convertAvDates() {
    avDates = [];
    avObjectDates = [];
    if (av.length > 0) {
      if (selectLocation === 0) {
        av.filter(
          (ava) => ava.recurring === false && ava.unavailable === false
        ).map((ava, index) => {
          let addday = new Date(ava.date).toDateString();
          let avid = ava.id;
          let arrayObject = {
            date: addday,
            id: avid,
            loc: ava.location,
          };
          avDates.push(addday);
          avObjectDates.push(arrayObject);
          setAvDates(avDates);
          setAvObjectDates(avObjectDates);
        });
        av.filter(
          (av) => av.recurring === true && av.unavailable === false
        ).map((ava, index) => {
          let datefrom = new Date(ava.date);
          let dateto = new Date(ava.dateto);
          let step = ava.recurringtype;
          let date = datefrom;
          let avid = ava.id;
          while (date < dateto) {
            var oneWeek = 7 * 24 * 60 * 60 * 1000; // one week in ms
            let adday = date.toDateString();
            let arrayObject = {
              date: adday,
              id: avid,
              loc: ava.location,
            };
            avDates.push(adday);
            avObjectDates.push(arrayObject);
            setAvDates(avDates);
            setAvObjectDates(avObjectDates);
            var msDate = date.getTime(); //date in ms
            var newDate = msDate + step * oneWeek;
            date = new Date(newDate);
          }
        });
      } else {
        av.filter(
          (ava) =>
            ava.recurring === false &&
            ava.unavailable === false &&
            ava.location === selectLocation
        ).map((ava, index) => {
          let addday = new Date(ava.date).toDateString();
          let avid = ava.id;
          let arrayObject = {
            date: addday,
            id: avid,
            loc: ava.location,
          };
          avDates.push(addday);
          avObjectDates.push(arrayObject);
          setAvDates(avDates);
          setAvObjectDates(avObjectDates);
        });
        av.filter(
          (av) =>
            av.recurring === true &&
            av.unavailable === false &&
            av.location === selectLocation
        ).map((ava, index) => {
          let datefrom = new Date(ava.date);
          let dateto = new Date(ava.dateto);
          let step = ava.recurringtype;
          let date = datefrom;
          let avid = ava.id;
          while (date < dateto) {
            var oneWeek = 7 * 24 * 60 * 60 * 1000; // one week in ms
            let adday = date.toDateString();
            let arrayObject = {
              date: adday,
              id: avid,
              loc: ava.location,
            };
            avDates.push(adday);
            avObjectDates.push(arrayObject);
            setAvDates(avDates);
            setAvObjectDates(avObjectDates);
            var msDate = date.getTime(); //date in ms
            var newDate = msDate + step * oneWeek;
            date = new Date(newDate);
          }
        });
      }
    }
  }

  function filterUnavailabilities() {
    unAvDates.map((unav, index) => {
      let unavobj = unAvObjectDates[index];
      if (avDates.includes(unav)) {
        let removeobject = avObjectDates
          .filter((avobj) => avobj.date === unavobj.date)
          .filter((avobj) => avobj.loc === unavobj.loc)[0];
        let pos = avObjectDates.indexOf(removeobject);
        avObjectDates.splice(pos, 1);
        avDates.splice(pos, 1);
        setAvObjectDates(avObjectDates);
        setAvDates(avDates);
      }
    });
  }

  const bookingCreate = async (cd, ts, loc) => {
    var url = process.env.REACT_APP_API_URL + "api/booking/create/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        shopsessiontoken: authObject.shopsessiontoken,
        webshop: authObject.webshoptoken,
        userid: authObject.user.id,
        date: new Date(cd.setHours(0, 0, 0, 0)),
        location: loc.name,
        slotfrom: ts.slotfrom,
        slotto: ts.slotto,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          shopObject.setbooking({ cd, ts, loc });
          navigate("/confirm/");
          // localStorage.removeItem("session");

          // setShopReset(!shopReset);
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  function onChange(nextValue) {
    setClickUnAvDate(null);
    setClickAvDate(null);
    setClickRecurDate(null);
    setValue(nextValue);
    setClickDate(nextValue);
    if (unAvDates.includes(nextValue.toDateString())) {
      setClickUnAvDate(nextValue.toDateString());
      setClickAvDate(null);
      setClickRecurDate(null);
    }
    if (avDates.includes(nextValue.toDateString())) {
      setClickAvDate(nextValue.toDateString());
    }
    if (today.toDateString() === nextValue.toDateString()) {
      let span = shopObject.shopoptions.samedayspan;
      var pushreference = new Date(),
        pushday = new Date(pushreference);
      pushday.setMinutes(pushreference.getMinutes() + span);
      setTimePusher(
        pushday.getHours() +
          ":" +
          pushday.getMinutes() +
          ":" +
          pushday.getSeconds()
      );
    }
  }

  function selectSlot(cd, ts, loc, av) {
    if (!loc.country) {
      loc.country = "";
    }
    if (!loc.city) {
      loc.city = "";
    }
    if (!loc.zip) {
      loc.zip = "";
    }
    if (!loc.street) {
      loc.street = "";
    }
    if (!loc.hnr) {
      loc.hnr = "";
    }
    if (!loc.hnrext) {
      loc.hnrext = "";
    }
    authObject.setshipaddress({
      ...authObject.shipaddress,
      ["title"]: "",
      ["firstname"]: "",
      ["name"]: loc.name,
      ["street"]: loc.street,
      ["hnr"]: loc.hnr,
      ["hnrext"]: loc.hnrext,
      ["zip"]: loc.zip,
      ["city"]: loc.city,
      ["country"]: loc.country,
    });
    authObject.setbilladdress({
      ...authObject.billaddress,
      ["title"]: authObject.user.title,
      ["firstname"]: authObject.user.firstname,
      ["name"]: authObject.user.name,
    });

    cd = new Date(cd);
    cd = new Date(cd.getFullYear(), cd.getMonth(), cd.getDate());

    // adjust for local timezone by adding 4 hours - if hours=0, python will mention day before
    cd = new Date(cd.setTime(cd.getTime() + 4 * 60 * 60 * 1000));

    shopObject.setshipmenttype("P");
    bookingCreate(cd, ts, loc);
  }

  // if date is returned, tile is disabled
  function tileDisabled({ date, view }) {
    if (view === "month") {
      if (date.setHours(0, 0, 0, 0) < datePusher) {
        return date;
      }
    }
  }

  // bepaald css van datumvakje
  function tileClassName({ date, view }) {
    let caldate = date.toDateString();
    if (date.setHours(0, 0, 0, 0) < datePusher || view !== "month") {
      return;
    }
    if (unAvDates.includes(caldate)) {
      return "ccocalsemibold";
    }
    if (unAvDates.includes(caldate) && avDates.includes(caldate)) {
      return "ccocalsemibold";
    }
    if (avDates.includes(caldate)) {
      return "ccocalbold";
    }
    return "ccocalempty";
  }

  return (
    <div className="ccobox">
      <div className="row">
        {shopObject.shopoptions.en_shipping && (
          <button
            className="btn btn-secondary btn-sm col-3 ms-3"
            onClick={(e) => setToggler(false)}
          >
            annuleer
          </button>
        )}
        <div className="col-6"></div>
      </div>
      <div className="h3 ccotitleline mt-2 fs-2 fw-bold">
        Afhalen op locatie
      </div>

      {clickAvDate === null && clickRecurDate === null && (
        <ShopCheckoutLocations
          loc={loc}
          selectLocation={selectLocation}
          setSelectLocation={setSelectLocation}
        />
      )}

      {clickAvDate === null && clickRecurDate === null && (
        <>
          <div className="ccosubtitle fs-2">Maak een keuze uit de kalender</div>
          <div className="ccocalendarbox">
            <Calendar
              onChange={onChange}
              value={value}
              tileDisabled={tileDisabled}
              tileClassName={tileClassName}
            />
            <div className="mt-2">Legende :</div>
            <div className="row">
              <div className="col-4">
                <div className="calbox ccocalbold text-center border-2 w-50">
                  X
                </div>
                <div>Normaal</div>
              </div>
              <div className="col-4">
                <div className="calbox ccocalsemibold text-center border-2 w-50">
                  X
                </div>
                Aangepast
              </div>
              <div className="col-4">
                <div className="calbox ccocalempty text-center border-2 w-50">
                  X
                </div>
                Geen
              </div>
            </div>
          </div>
        </>
      )}

      <div className="ccoconfirmation">
        {clickAvDate !== null && (
          <>
            {avObjectDates && (
              <>
                <div className="mb-2">
                  Op <strong>{clickAvDate}</strong> is ophalen mogelijk op
                  volgende locatie:
                </div>
                {selectLocation === 0 && (
                  <>
                    <ShopCheckoutShowAv
                      availabilities={avObjectDates.filter(
                        (avod) => avod.date === clickAvDate
                      )}
                      selectSlot={selectSlot}
                      loc={loc}
                      ts={ts}
                      timePusher={timePusher}
                      datePusher={datePusher}
                      clickAvDate={clickAvDate}
                      setClickAvDate={setClickAvDate}
                    />
                  </>
                )}

                {selectLocation !== 0 && (
                  <>
                    <ShopCheckoutShowAv
                      availabilities={avObjectDates.filter(
                        (avod) =>
                          avod.date === clickAvDate &&
                          avod.loc === selectLocation
                      )}
                      selectSlot={selectSlot}
                      loc={loc}
                      ts={ts}
                      timePusher={timePusher}
                      datePusher={datePusher}
                      clickAvDate={clickAvDate}
                      setClickAvDate={setClickAvDate}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ShopCheckout;
