import React, { useState, Fragment, useEffect } from "react";

const Catbuttons = ({
  cat,
  setCat,
  subCat,
  setSubCat,
  catList,
  subCatList,
  favorites,
  user,
}) => {
  const [subCats, setSubCats] = useState([]);

  useEffect(() => {
    if (cat != null) {
      setSubCats(subCatList.filter((sc) => sc.category === cat.id));
    } else {
      setSubCats(null);
    }
  }, [cat]);

  useEffect(() => {
    if (cat === "fav") {
      setCat(null);
    }
  }, [user]);

  useEffect(() => {
    if (typeof favorites != undefined && cat === "fav") {
      if (favorites.length > 0) {
        setCat(null);
      }
    }
  }, [favorites]);

  const clickCatHandler = (cat) => {
    setCat(cat);
    setSubCat(null);
  };

  const clickSubCatHandler = (subcat) => {
    setSubCat(subcat);
  };

  return (
    <div>
      {catList && (
        <div className="catgroup">
          {/* ---------------------maincatbuttons--------------------- */}
          <div className="maincatgroup">
            {catList.map((ct, index) => {
              return (
                <Fragment key={index}>
                  {ct !== cat && (
                    <button
                      className="catbutton"
                      onClick={(e) => clickCatHandler(ct)}
                    >
                      <h3 className="catbuttontext">{ct.name}</h3>
                    </button>
                  )}

                  {ct === cat && (
                    <button
                      className="catbutton buttonselected"
                      onClick={(e) => clickCatHandler(ct)}
                    >
                      <h3 className="catbuttontext">
                        <strong>{ct.name}</strong>
                      </h3>
                    </button>
                  )}
                </Fragment>
              );
            })}
            {favorites && user !== null && (
              <>
                {cat !== "fav" && (
                  <button
                    className="catbutton"
                    onClick={(e) => clickCatHandler("fav")}
                  >
                    <h3 className="catbuttontext">Favorieten</h3>
                  </button>
                )}

                {cat === "fav" && (
                  <button
                    className="catbutton buttonselected"
                    onClick={(e) => clickCatHandler("fav")}
                  >
                    <h3 className="catbuttontext">
                      <strong>Favorieten</strong>
                    </h3>
                  </button>
                )}
              </>
            )}
          </div>
          {/* ---------------------crumbtracker--------------------- */}
          {cat && subCats && (
            <div className="fst-italic w-100">
              {/* ---------------------subcatbuttons--------------------- */}
              {subCats.length > 0 && (
                <div className="subcatgroup w-100">
                  {subCats.map((sc, index2) => {
                    return (
                      <Fragment key={index2}>
                        {subCat != sc && (
                          <>
                            <button
                              className="subcatbutton"
                              onClick={(e) => clickSubCatHandler(sc)}
                              key={index2}
                            >
                              <span>{sc.name}</span>
                            </button>
                          </>
                        )}
                        {subCat === sc && (
                          <>
                            <button
                              className="subcatbutton buttonselected"
                              onClick={(e) => clickSubCatHandler(sc)}
                              key={index2}
                            >
                              <span>{sc.name}</span>
                            </button>
                          </>
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              )}

              <div className="crumbstrail row mt-2 mb-2 ms-1">
                <div className="">
                  <button
                    className="crumbsbutton ms-2 pe-auto border-0"
                    onClick={() => {
                      setCat(null);
                      setSubCat(null);
                    }}
                  >
                    <u>Start</u>
                  </button>
                  <span className="ms-2">{">"}</span>
                  <span className="ms-2">{cat.name}</span>
                  <span className="ms-2">{">"}</span>
                  {subCat != null && (
                    <>
                      <button
                        className="crumbsbutton ms-2 pe-auto border-0"
                        onClick={() => {
                          setSubCat(null);
                        }}
                      >
                        <u>Alle subrubrieken</u>
                      </button>
                      <span className="ms-2">{">"}</span>
                      <span className="ms-2">
                        <strong>{subCat.name}</strong>
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Catbuttons;
