import React, { useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../Store/auth-context";

const Activate = () => {
  const { uid, token } = useParams();
  const authObject = useContext(AuthContext);
  authObject.setactivating(true);

  console.log("params : uid :", uid, " token :", token);

  let navigate = useNavigate();

  const activateAccount = (e) => {
    e.preventDefault();
    authObject.verifyaccount(uid, token);
  };

  if (authObject.verified) {
    navigate("/login/");
  }

  return (
    <div className="activatepage">
      <div className="h1 activatetitle">Account activeren</div>
      <div className="activateline">
        <p>Gelieve op de knop te drukken om je account te activeren</p>
      </div>
      <div className="activateline">
        <button
          className="btn btn-primary activatebutton"
          onClick={activateAccount}
        >
          Activate
        </button>
      </div>
    </div>
  );
};

export default Activate;
