import React from "react";
import { useContext } from "react";
import AuthContext from "../Store/auth-context";

import GetCountry from "./GetCountry";
import GetTitle from "./GetTitle";

const ShipAddressDetails = () => {
  const authObject = useContext(AuthContext);
  return (
    <div>
      <div className="addressbox_line">
        <div className="addressbox_line_left">Naam</div>
        <div className="addressbox_line_right">
          <GetTitle code={authObject.shipaddress.title} />{" "}
          {authObject.shipaddress.firstname} {authObject.shipaddress.name}
        </div>
      </div>
      <div className="addressbox_line">
        <div className="addressbox_line_left">Straat en Nr Bus</div>
        <div className="addaddressbox_line_rightresslineright">
          {authObject.shipaddress.street} {authObject.shipaddress.hnr}{" "}
          {authObject.shipaddress.hnrext}
        </div>
      </div>
      <div className="addressbox_line">
        <div className="addressbox_line_left">Postcode</div>
        <div className="addressbox_line_right">
          {authObject.shipaddress.zip}
        </div>
      </div>
      <div className="addressbox_line">
        <div className="addressbox_line_left">Stad</div>
        <div className="addressbox_line_right">
          {authObject.shipaddress.city}
        </div>
      </div>
      <div className="addressbox_line">
        <div className="addressbox_line_left">Land</div>
        <div className="addressbox_line_right">
          <GetCountry code={authObject.shipaddress.country} />
        </div>
      </div>
    </div>
  );
};

export default ShipAddressDetails;
