import React from "react";
import SelectSlot from "./SelectSlot";
import LocBlock from "./LocBlock";

const ShopCheckoutShowAv = ({
  availabilities,
  selectSlot,
  loc,
  ts,
  timePusher,
  datePusher,
  clickAvDate,
  setClickAvDate,
}) => {
  return (
    <>
      {availabilities.map((avod, index) => {
        return (
          <div key={index}>
            <div className="ccoconfirmation">
              <div>
                {loc
                  .filter((loc) => loc.id === avod.loc)
                  .map((loc, index2) => {
                    return (
                      <div
                        className="bg-secondary bg-opacity-50 pb-2 rounded"
                        key={index2}
                      >
                        <LocBlock loc={loc} />
                        <div className="d-flex justify-content-center">
                          <button
                            className="btn btn-success btn-sm my-3"
                            onClick={(e) => {
                              setClickAvDate(null);
                            }}
                          >
                            Kies een andere dag
                          </button>
                        </div>
                        <div className="ms-2">Kies een tijdslot</div>
                        <div>
                          <SelectSlot
                            tsarray={ts}
                            av={avod}
                            loc={loc}
                            selectSlot={selectSlot}
                            clickDate={clickAvDate}
                            timePusher={timePusher}
                            datePusher={datePusher}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ShopCheckoutShowAv;
