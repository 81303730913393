import React from "react";

import AllergenBox from "./AllergenBox";

import eieren from "../Media/allergens/ei.webp";
import gluten from "../Media/allergens/gluten.webp";
import lupine from "../Media/allergens/lupine.webp";
import melk from "../Media/allergens/melk.webp";
import mosterd from "../Media/allergens/mosterd.webp";
import pinda from "../Media/allergens/pinda.webp";
import schaaldieren from "../Media/allergens/schaaldier.webp";
import schaalvruchten from "../Media/allergens/schaalvrucht.webp";
import selder from "../Media/allergens/selder.webp";
import sesam from "../Media/allergens/sesam.webp";
import soya from "../Media/allergens/soya.webp";
import sulfiet from "../Media/allergens/sulfiet.webp";
import vis from "../Media/allergens/vis.webp";
import weekdier from "../Media/allergens/weekdier.webp";

const DetailAllergens = ({ allergens }) => {
  return (
    <div className="mt-5 bg-white p-3 w-75 mx-auto text-black">
      <div className="h3 text-black">Allergenen</div>
      <AllergenBox
        image={gluten}
        allergen="glutenbevattende granen (tarwe,rogge,gerst,haver en spelt)"
        value={allergens.gluten}
      />
      <AllergenBox
        image={schaaldieren}
        allergen="schaaldieren"
        value={allergens.schaaldieren}
      />
      <AllergenBox image={eieren} allergen="eieren" value={allergens.ei} />
      <AllergenBox image={vis} allergen="vis" value={allergens.vis} />
      <AllergenBox
        image={pinda}
        allergen="pinda's (aardnoten)"
        value={allergens.pinda}
      />
      <AllergenBox image={soya} allergen="soja" value={allergens.soja} />
      <AllergenBox
        image={melk}
        allergen="melk en zuivelproducten (incl.lactose)"
        value={allergens.melk}
      />
      <AllergenBox
        image={schaalvruchten}
        allergen="schaalvruchten (amandelen,hazel-,wal-, cashewn-,pecan-,para-,pistach- en macadamianoten)"
        value={allergens.schaalvruchten}
      />
      <AllergenBox image={selder} allergen="selder" value={allergens.selder} />
      <AllergenBox
        image={mosterd}
        allergen="mosterd"
        value={allergens.mosterd}
      />
      <AllergenBox image={sesam} allergen="sesamzaad" value={allergens.sesam} />
      <AllergenBox
        image={sulfiet}
        allergen="sulfieten"
        value={allergens.sulfiet}
      />
      <AllergenBox image={lupine} allergen="lupine" value={allergens.lupine} />
      <AllergenBox
        image={weekdier}
        allergen="weekdieren zoals mosselen en oesters"
        value={allergens.week}
      />
      <div className="row w-100 d-flex align-items-center">
        <div className="col-2">Nota :</div>
        <div className="col-10">{allergens.remarks}</div>
      </div>
    </div>
  );
};

export default DetailAllergens;
