import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Store/auth-context";
import useLayout from "../Functions/useLayout";

const SuccessPage = () => {
  const navigate = useNavigate();
  const authObject = useContext(AuthContext);

  if (!authObject.accesstoken) {
    navigate("/");
  }

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 5000);
  }, []);

  return (
    <div
      className="successpage text-center"
      style={{ backgroundColor: useLayout(14, 0), color: useLayout(14, 1) }}
    >
      <p className="text-center mt-5 fs-3">We danken u voor uw bestelling</p>
      <div className="successpar d-flex flex-column">
        <button
          className="btn btn-success successbutton mb-3"
          onClick={(e) => navigate("/")}
        >
          Verder naar shop
        </button>
        <p>(venster sluit in enkele seconden)</p>
      </div>
      <div className="successpar">
        <div className="mx-auto fs-4">
          <p>
            powered by{" "}
            <a
              className="link-primary ms-2"
              href="https://ubicopia.be"
              target="_blank"
              rel="noreferrer"
            >
              Ubicopia
            </a>
          </p>
        </div>
      </div>
      <div>Ubicopia is een e-commerce SAAS-product van DRO Projects bv.</div>
      <div>Sint-Hubertusstraat 120 - 3300 Tienen</div>
    </div>
  );
};

export default SuccessPage;
