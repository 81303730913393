import React from "react";

const AllergenBox = ({ image, allergen, value }) => {
  return (
    <>
      {value && (
        <>
          <div className="row w-100 d-flex align-items-center">
            <div className="col-2">
              <img src={image} width="25" />
            </div>
            <div className="col-10">{allergen}</div>
          </div>
        </>
      )}
    </>
  );
};

export default AllergenBox;
