import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Store/auth-context";
import ShopContext from "../Store/shop-context";

const AbortPage = () => {
  const navigate = useNavigate();
  const authObject = useContext(AuthContext);
  const shopObject = useContext(ShopContext);

  if (!authObject.accesstoken) {
    navigate("/");
  }

  const abortOrder = () => {
    let pm = "A";
    shopObject.orderconfirmpayment(pm);
    navigate("/");
  };

  return (
    <div className="successpage">
      <div className="successpar fs-2">
        Het ziet ernaar uit dat u de betaling hebt afgebroken.
      </div>
      <div className="successpar">
        <div className="">Wenst u een ander betaalmiddel te kiezen ?</div>
      </div>
      <button
        className="btn btn-primary successbutton"
        onClick={(e) => navigate("/paypage/")}
      >
        Terug naar betaalselectie
      </button>

      <div className="successpar">
        of wenst u de shop te verlaten en de bestelling te annuleren ?
      </div>
      <button
        className="btn btn-danger dangerbutton mb-4"
        onClick={(e) => abortOrder()}
      >
        Annuleer
      </button>
    </div>
  );
};

export default AbortPage;
