import React, { useState, useContext, useEffect, Fragment } from "react";
import AuthContext from "../Store/auth-context";
import ShopContext from "../Store/shop-context";

const CartItemLineCTW = ({ cartitem }) => {
  const authObject = useContext(AuthContext);
  const shopObject = useContext(ShopContext);

  const [ci, setCi] = useState(cartitem);
  const [qty, setQty] = useState(cartitem.quantity);

  const [edit, setEdit] = useState(false);

  const onChange = (e) => {
    e.target.value >= 0 ? setQty(parseInt(e.target.value)) : setQty(0);
  };

  function update() {
    cartitem = { ...cartitem, ["quantity"]: qty };
    // setQty(cartitem.quantity);
    setCi(cartitem);
    console.log("cartitem update :", cartitem);
    shopObject.cartitemupdate(cartitem);
    setEdit(!edit);
  }

  function toggleEdit(e) {
    setQty(cartitem.quantity);
    setEdit(!edit);
  }

  useEffect(() => {
    setQty(cartitem.quantity);
  }, []);

  return (
    <>
      <tr>
        <td>{cartitem.name}</td>
        <td>
          {(cartitem.cartitemprice * (1 + cartitem.cartitemvat)).toFixed(2)}
        </td>
        {!edit && (
          <td>
            <div className="cartquantitygroup">
              <div>
                {cartitem.quantity}x{cartitem.weight}g
              </div>

              <div className="vergroup"></div>
            </div>
          </td>
        )}
        {edit && (
          <td>
            <div className="cartquantitygroup">
              <div className="">
                <input
                  className="cartquantityfield"
                  type="number"
                  value={qty}
                  onChange={onChange}
                />
                x{cartitem.weight}g
              </div>
            </div>
          </td>
        )}

        <td>
          {(
            (cartitem.cartitemprice * (1 + cartitem.cartitemvat)).toFixed(2) *
            cartitem.quantity
          ).toFixed(2)}
        </td>
        <td>
          {!edit && (
            <>
              <i
                className="bi bi-pen cartitemicon"
                onClick={(e) => setEdit(true)}
              ></i>
              <i
                className="bi bi-trash cartitemicon"
                onClick={(e) => shopObject.cartitemdelete(cartitem.id)}
              ></i>
            </>
          )}
          {edit && (
            <>
              <i
                className="bi bi-cart-check cartitemicon"
                onClick={(e) => update()}
              ></i>
            </>
          )}
        </td>
      </tr>
      {cartitem.options && cartitem.options.length > 0 && (
        <>
          <tr>
            <td></td>
            <td>
              <small>keuzeopties</small>
            </td>
            <td>
              {cartitem.options.map((option, index) => {
                return (
                  <Fragment key={index}>
                    <small>
                      {index > 0 && <>{", "}</>}
                      {shopObject.cartoptions.filter(
                        (co) => co.id === option
                      )[0] && (
                        <>
                          {
                            shopObject.cartoptions.filter(
                              (co) => co.id === option
                            )[0].description
                          }
                        </>
                      )}
                    </small>
                  </Fragment>
                );
              })}
            </td>
          </tr>
        </>
      )}
    </>
  );
};

export default CartItemLineCTW;
