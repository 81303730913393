import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Store/auth-context";
import ShopContext from "../Store/shop-context";

import checkLeadtimes from "../Functions/checkLeadtimes";

const PNLCheckout = ({ toggler, setToggler }) => {
  const authObject = useContext(AuthContext);
  const shopObject = useContext(ShopContext);
  var url = "";
  var apikey = "";
  var navigate = useNavigate();

  var [crossList, setCrossList] = useState([]);
  var [orgCorrAddress, setOrgCorrAddress] = useState("");

  var [datePusher, setDatePusher] = useState(
    shopObject.shopoptions.allowsameday
      ? new Date(new Date().setHours(0, 0, 0, 0))
      : new Date()
  );
  const webshop = authObject.webshoptoken;

  const [doToggler, setDoToggler] = useState(false);
  const [delOp, setDelOp] = useState();
  const [pickup, setPickup] = useState();
  const sa = authObject.shipaddress;

  // start by loading the crosslist
  useEffect(() => {
    getCrossList();
    getorgCorrAddress();
  }, []);

  // when the crosslist is loaded perform the leadtime determination, result is datepusher
  useEffect(() => {
    if (crossList.length > 0) {
      var result = checkLeadtimes(
        shopObject.cart,
        crossList,
        shopObject.productsettings
      );
      setDatePusher(result.day);
    }
  }, [crossList.length > 0]);

  const getCrossList = async () => {
    let response = await fetch(
      process.env.REACT_APP_API_URL + "api/product-x-list/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshop: webshop,
        }),
      }
    );
    let data = await response.json();
    if (response.status === 200) {
      setCrossList(data);
    }
  };

  const getorgCorrAddress = async () => {
    let response = await fetch(
      process.env.REACT_APP_API_URL + "api/address/org/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshop: webshop,
        }),
      }
    );
    let data = await response.json();
    if (response.status === 200) {
      setOrgCorrAddress(data);
    }
  };

  const shipOrderInfo = async () => {
    var item = {
      product: 0,
      name: "shipment",
      sku: "0000000000",
      description: "Shipment",
      quantity: 1,
      cartitemprice: 12.39669,
      cartitemtotal: 12.39669,
      cartitemvat: 0.21,
      varianttype: "standard",
      ordered: true,
      shopsessiontoken: authObject.shopsessiontoken,
      webshop: authObject.webshoptoken,
    };

    shopObject.cartitemcreate(item);

    let orderdate = new Date(datePusher);

    let y = orderdate.getFullYear();
    let m = orderdate.getMonth() + 1;
    let d = orderdate.getDate();
    let pnldate = d.toString() + "-" + m.toString() + "-" + y.toString();

    if (shopObject.shopoptions.en_postnl_sandbox) {
      url = "https://api-sandbox.postnl.nl/shipment/v1/checkout";
      apikey = authObject.organisation.api_pnl_sandbox;
    } else {
      url = "https://api.postnl.nl/shipment/v1/checkout";
      apikey = authObject.organisation.api_pnl_real;
    }

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apikey: apikey,
      },
      body: JSON.stringify({
        OrderDate: pnldate,
        ShippingDuration: "2",
        CutOffTimes: [
          {
            Day: "00",
            Available: "true",
            Type: "regular",
            Time: "20:00:00",
          },
        ],
        HolidaySorting: "false",
        Options: ["Daytime", "Pickup"],
        Locations: "3",
        Days: "2",
        Addresses: [
          {
            AddressType: "01",
            Street: sa.street,
            HouseNr: sa.hnr,
            HouseNrExt: sa.hnrext,
            Zipcode: sa.zip,
            City: sa.city,
            Countrycode: sa.country,
          },
          {
            AddressType: "02",
            Street: orgCorrAddress.street,
            HouseNr: orgCorrAddress.hnr,
            HouseNrExt: orgCorrAddress.hnrext,
            Zipcode: orgCorrAddress.zip,
            City: orgCorrAddress.city,
            Countrycode: orgCorrAddress.country,
          },
        ],
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setDelOp(resp.DeliveryOptions);
        setPickup(resp.PickupOptions);
      });
  };

  const selectAddressDelivery = (date) => {
    authObject.setbilladdress({
      ...authObject.billaddress,
      ["title"]: authObject.user.title,
      ["firstname"]: authObject.user.firstname,
      ["name"]: authObject.user.name,
    });
    shopObject.setshipmenttype("M");
    let d = date.slice(0, 2);
    let m = date.slice(3, 5);
    let y = date.slice(6, 10);
    date = new Date(y, m - 1, d);
    // adjust for local timezone by adding 4 hours - if hours=0, python will mention day before
    date = new Date(date.setTime(date.getTime() + 4 * 60 * 60 * 1000));

    shopObject.setdeldate(date);

    setDoToggler(false);
    navigate("/confirm/");
  };

  const selectPickupDelivery = (
    date,
    company,
    street,
    hnr,
    hnrext,
    zip,
    city,
    country
  ) => {
    authObject.setshipaddress({
      ...authObject.shipaddress,
      ["title"]: "",
      ["firstname"]: company + " for ",
      ["name"]: authObject.user.firstname + " " + authObject.user.name,
      ["street"]: street,
      ["hnr"]: hnr.toString(),
      ["hnrext"]: hnrext,
      ["zip"]: zip,
      ["city"]: city,
      ["country"]: country,
    });
    authObject.setbilladdress({
      ...authObject.billaddress,
      ["title"]: authObject.user.title,
      ["firstname"]: authObject.user.firstname,
      ["name"]: authObject.user.name,
    });
    shopObject.setshipmenttype("M");
    let d = date.slice(0, 2);
    let m = date.slice(3, 5);
    let y = date.slice(6, 10);
    date = new Date(y, m - 1, d);
    // adjust for local timezone by adding 4 hours - if hours=0, python will mention day before
    date = new Date(date.setTime(date.getTime() + 4 * 60 * 60 * 1000));
    shopObject.setdeldate(date);
    setDoToggler(false);
    navigate("/confirm/");
  };

  return (
    <div className="addressbox">
      <h2 className="mt-5 text-center">Levering met POSTNL</h2>

      {!doToggler && (
        <div className="container text-center py-2 my-2">
          <div className="">
            Controleer je adressen en bevestig de verzending.
          </div>
          <div className="">
            Een extra kost van 15,-EUR incl.BTW wordt toegevoegd aan uw
            winkelmandje
          </div>

          <div className="row mb-3 mt-3 d-flex justify-content-evenly">
            {shopObject.shopoptions.en_pickup && (
              <>
                <button
                  className="btn btn-secondary btn-sm col-6 w-25"
                  onClick={(e) => {
                    setToggler(false);
                  }}
                >
                  Annuleer
                </button>
              </>
            )}

            <button
              className="btn btn-primary btn-sm col-6 w-25"
              onClick={(e) => {
                shipOrderInfo();
                setDoToggler(true);
              }}
            >
              Toevoegen
            </button>
          </div>
        </div>
      )}

      {doToggler && (
        <>
          <div className="pnlline">Een verzending is toegevoegd</div>
          <div className="pnlline">
            Kies één van de mogelijkheden hieronder :
          </div>
          {delOp && (
            <>
              <div className="pnlline mt-3">
                <strong>Levering op uw leveradres</strong>
              </div>
              {delOp.map((delop, index) => {
                return (
                  <div key={index} className="pnldeliveryoption">
                    {delop.Timeframe &&
                      delop.Timeframe.map((tf, index2) => {
                        return (
                          <button
                            className="btn btn-primary pnlselectbutton"
                            key={index2}
                            onClick={(e) => {
                              selectAddressDelivery(delop.DeliveryDate);
                            }}
                          >
                            <div className="col">
                              Levering op datum{" "}
                              <strong>{delop.DeliveryDate}</strong> tussen{" "}
                              {tf.From.slice(0, 5)} en {tf.To.slice(0, 5)}
                            </div>
                          </button>
                        );
                      })}
                  </div>
                );
              })}
            </>
          )}
          {pickup && (
            <>
              <div className="pnlline mt-3">
                <strong>Ophalen in uw buurt</strong>
              </div>
              {pickup.map((pu, index) => {
                return (
                  <div key={index} className="pnldeliveryoption mb-3">
                    {pu.Locations.map((puloc, index2) => {
                      return (
                        <button
                          className="btn btn-primary pnlselectbutton"
                          key={index2}
                          onClick={(e) => {
                            selectPickupDelivery(
                              pu.PickupDate,
                              puloc.Address.CompanyName,
                              puloc.Address.Street,
                              puloc.Address.HouseNr,
                              "",
                              puloc.Address.Zipcode,
                              puloc.Address.City,
                              puloc.Address.Countrycode
                            );
                          }}
                        >
                          <div>afstand : {puloc.Distance} m</div>
                          <div>
                            Vanaf <strong>{pu.PickupDate}</strong> op{" "}
                            {pu.Option}{" "}
                          </div>
                          <div>{puloc.Address.CompanyName}</div>
                          <div>
                            {puloc.Address.Street} {puloc.Address.HouseNr}
                          </div>
                          <div>
                            {puloc.Address.Countrycode}
                            {puloc.Address.Zipcode} {puloc.Address.City}
                          </div>
                        </button>
                      );
                    })}
                  </div>
                );
              })}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PNLCheckout;
