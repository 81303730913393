import React, { useState, useContext } from "react";
import AuthContext from "../Store/auth-context";
import { titles } from "../Data/data";
import GetTitle from "./GetTitle";

const UserBox = () => {
  const authObject = useContext(AuthContext);
  const [user, setUser] = useState(authObject.user);

  const [clickedEdit, setClickedEdit] = useState(false);

  const updateUserDetails = async (user) => {
    user.webshop = authObject.webshoptoken;
    var url = `${process.env.REACT_APP_API_URL}api/userdetails/update/`;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + localStorage.getItem("access"),
      },
      body: JSON.stringify(user),
    })
      .then((resp) => {
        if (resp.ok) {
          console.log("details updated");
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  function clickEdit() {
    setClickedEdit(true);
  }
  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setUser({ ...user, [name]: value });
  }

  const handleUpdate = (e) => {
    e.preventDefault();
    updateUserDetails(user);
    setClickedEdit(false);
  };

  return (
    <div className="userbox px-1 py-3">
      {!clickedEdit && (
        <>
          <div className="h3 text-center">Uw data</div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-light">Naam</div>
            <div className="col-sm-6">
              <GetTitle code={user.title} /> {user.firstname} {user.name}
            </div>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-light">Email</div>
            <div className="col-sm-6">{user.email}</div>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-light">Tel.</div>
            <div className="col-sm-6">{user.phone}</div>
          </div>
          <div className="userbuttongroup">
            <div></div>
            <button
              className="btn btn-secondary btn-sm"
              onClick={() => clickEdit()}
            >
              Edit
            </button>
          </div>
        </>
      )}
      {clickedEdit && (
        <form onSubmit={(e) => handleUpdate(e)}>
          <div className="h3 text-center">Uw data</div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-bold">Naam</div>
            <div className="col-sm-6">
              <div className="form-group">
                <select
                  className="form-control"
                  placeholder="Title"
                  type="text"
                  required
                  id="title"
                  name="title"
                  value={user.title}
                  onChange={handleChange}
                >
                  <option name="title" value="">
                    - titel -
                  </option>
                  {titles &&
                    titles.map((tit, index) => {
                      return (
                        <option name="title" value={tit.code} key={index}>
                          {tit.name}
                        </option>
                      );
                    })}
                </select>
                <input
                  className="form-control"
                  type="text"
                  id="firstname"
                  name="firstname"
                  value={user.firstname}
                  onInput={handleChange}
                />
                <input
                  className="form-control"
                  type="text"
                  id="name"
                  name="name"
                  value={user.name}
                  onInput={handleChange}
                  pattern="^[a-zA-Z][a-zA-Z0-9éèçà-\s]{1,255}$"
                ></input>
              </div>
            </div>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-bold">
              Email<small> (This cannot be changed) </small>
            </div>
            <div className="col-sm-6">{user.email}</div>
          </div>
          <div className="row ms-3">
            <div className="col-sm-6 fw-bold">Tel.</div>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="phone"
                id="phone"
                name="phone"
                value={user.phone}
                onInput={handleChange}
                pattern="^(?:0|\(?\+.+.\)?\s?|00.+.\s?)[1-999](?:[\.\-\s]?\d\d){4}$"
              ></input>
            </div>
          </div>

          <div className="row mt-3 d-flex justify-content-evenly">
            <button className="btn btn-primary btn-sm col-6 w-25" type="submit">
              Update
            </button>
            <button
              className="btn btn-secondary btn-sm col-6 w-25"
              onClick={() => setClickedEdit(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default UserBox;
