import React from "react";

const timetoSeconds = (time) => {
  let hours = parseInt(time.slice(0, 2));
  let minutes = parseInt(time.slice(3, 5));
  let seconds = hours * 3600 + minutes * 60;
  return seconds;
};

export default timetoSeconds;
