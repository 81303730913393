import { createContext, useState, useEffect } from "react";

import jwt_decode from "jwt-decode";
import createToken from "../Functions/createToken";
import { useNavigate } from "react-router-dom";
import { blankaddress } from "../Data/data";

const AuthContext = createContext({
  user: null,
  organisation: null,
  favorites: [],

  accesstoken: null,
  webshoptoken: null,
  shopsessiontoken: null,

  shipaddress: [],
  setshipaddress: () => {},

  billaddress: [],
  setbilladdress: () => {},

  addresslist: [],
  setaddresslist: () => {},

  getaddresslist: () => {},

  createshopsession: () => {},
  getshopsessiontoken: () => {},
  loginuser: () => {},
  logoutuser: () => {},
  getuserdetails: () => {},

  signup: () => {},
  verifyaccount: () => {},
  activating: [],
  setactivating: () => {},
  verified: [],
  setverified: () => {},

  resetpassword: () => {},
  resetpasswordconfirm: () => {},
  errormessage: [],
  seterrormessage: () => {},
});

export function AuthProvider(props) {
  let [webShop, setWebShop] = useState(process.env.REACT_APP_SHOP_REF);
  let [accessToken, setAccessToken] = useState(() =>
    localStorage.getItem("access") ? localStorage.getItem("access") : null
  );
  let [refreshToken, setRefreshToken] = useState(() =>
    localStorage.getItem("refresh") ? localStorage.getItem("refresh") : null
  );
  let [userId, setUserId] = useState();
  let [user, setUser] = useState();
  let [organisation, setOrganisation] = useState();
  let [userName, setUserName] = useState();
  let [userEmail, setUserEmail] = useState();
  let [loading, setLoading] = useState(true);
  let [shopSessionToken, setShopSessionToken] = useState(false);
  let [billAddress, setBillAddress] = useState(blankaddress);
  let [shipAddress, setShipAddress] = useState(blankaddress);
  let [addressList, setAddressList] = useState([]);
  let [activating, setActivating] = useState(false);
  let [verified, setVerified] = useState(false);
  let [errorMessage, setErrorMessage] = useState("");
  let [favorites, setFavorites] = useState();

  const navigate = useNavigate();

  const createShopSession = () => {
    let newtoken = createToken(21);
    localStorage.setItem("session", newtoken);
    setShopSessionToken(newtoken);
  };

  // create a new session when no refreshtoken
  useEffect(() => {
    if (!localStorage.getItem("refresh")) {
      logoutUser();
    }
  }, []);

  // set accesstoken timing here
  useEffect(() => {
    if (loading) {
      updateToken();
    }
    let thirtyMinutes = 1000 * 60 * 30;
    let interval = setInterval(() => {
      if (refreshToken) {
        updateToken();
      }
    }, thirtyMinutes);
    return () => clearInterval(interval);
  }, [accessToken, loading]);

  useEffect(() => {
    if (localStorage.getItem("access")) {
      let access = localStorage.getItem("access");
      getUserDetails(jwt_decode(access).user_id, webShop);
    }
  }, [accessToken]);

  useEffect(() => {
    if (localStorage.getItem("session")) {
      setShopSessionToken(localStorage.getItem("session"));
    } else {
      let newtoken = createToken(7);
      localStorage.setItem("session", newtoken);
      setShopSessionToken(newtoken);
    }
    getOrganisationDetails(webShop);
  }, [loading]);

  // checked
  const signUp = async (
    email,
    phone,
    title,
    firstname,
    name,
    password,
    re_password,
    consent,
    subscribed
  ) => {
    var url = process.env.REACT_APP_API_URL + "api/users/register/";
    var webshop = webShop;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        phone,
        title,
        firstname,
        name,
        password,
        re_password,
        consent,
        subscribed,
        webshop,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {})
      .then((error) => (error ? console.log("error :", error) : null));
  };

  // checked
  const verifyAccount = async (uid, token) => {
    var url = `${process.env.REACT_APP_API_URL}api/users/verify/${uid}/${token}/`;
    var webshop = webShop;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      // .then((resp) => resp.json())
      .then((resp) => {
        if (resp.ok) {
          setVerified(true);
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const resetPassword = async (email) => {
    var webshop = webShop;
    var url = process.env.REACT_APP_API_URL + "api/users/password-reset/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, webshop }),
    })
      .then((resp) => resp.json())
      .then((resp) => {})
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const resetPasswordConfirm = async (
    uid,
    token,
    new_password,
    re_new_password
  ) => {
    var url =
      process.env.REACT_APP_API_URL + "api/users/password-reset-confirm/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uid, token, new_password, re_new_password }),
    })
      .then((resp) => resp.json())
      .then((resp) => {})
      .then((error) => (error ? console.log("error :", error) : null));
  };

  // checked
  const loginUser = async (email, password) => {
    var response = await fetch(
      process.env.REACT_APP_API_URL + "auth/jwt/create/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          usercode: webShop + "_" + email,
          password: password,
        }),
      }
    );
    let data = await response.json();
    if (response.status === 200) {
      setAccessToken(data.access);
      setRefreshToken(data.refresh);
      setUserId(jwt_decode(data.access).user_id);
      localStorage.setItem("access", data.access);
      localStorage.setItem("refresh", data.refresh);
      setErrorMessage("");
      navigate("/");
    } else {
      setErrorMessage(
        "Foute login, paswoord, of je email-adres is niet actief."
      );
    }
  };

  //Checked
  const logoutUser = () => {
    setAccessToken(null);
    setRefreshToken(null);
    setUser(null);
    setUserId(null);
    setUserEmail(null);
    setUserName(null);
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("session");
    createShopSession();
    // if (!activating) {
    //   navigate("/");
    // }
  };

  //Checked
  const updateToken = async () => {
    const updateBody = { refresh: localStorage.getItem("refresh") };
    let response = await fetch(
      process.env.REACT_APP_API_URL + "auth/jwt/refresh/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updateBody),
      }
    );

    let data = await response.json();
    if (response.status === 200) {
      setAccessToken(data.access);
      localStorage.setItem("access", data.access);
      setUserId(jwt_decode(data.access).user_id);
    } else {
      if (activating === false) {
        logoutUser();
      }
    }
    if (loading) {
      setLoading(false);
    }
  };

  let getUserDetails = async (userid, webshop) => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "api/userdetails/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        userid: userid,
        webshop: webshop,
      }),
    });
    if (resp.status === 200) {
      let data = await resp.json();
      setFavorites(data.favorites);
      setUser(data);
      getAddressList(userid);
    } else {
      logoutUser();
    }
  };

  let getOrganisationDetails = async (webshop) => {
    let response = await fetch(
      process.env.REACT_APP_API_URL + "api/organisation/details/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          webshop: webshop,
        }),
      }
    );
    let data = await response.json();
    setOrganisation(data);
  };

  let getAddressList = async (userid) => {
    var url = `${process.env.REACT_APP_API_URL}api/address/list/${userid}/`;
    let response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + localStorage.getItem("access"),
      },
    });
    let data = await response.json();
    setAddressList(data);
  };

  let context = {
    user: user,
    organisation: organisation,
    favorites: favorites,
    shipaddress: shipAddress,
    setshipaddress: setShipAddress,
    billaddress: billAddress,
    setbilladdress: setBillAddress,
    addresslist: addressList,
    setaddresslist: setAddressList,
    getaddresslist: getAddressList,
    webshoptoken: webShop,
    accesstoken: accessToken,
    shopsessiontoken: shopSessionToken,
    createshopsession: createShopSession,
    loginuser: loginUser,
    logoutuser: logoutUser,
    signup: signUp,
    verifyaccount: verifyAccount,
    activating: activating,
    setactivating: setActivating,
    getuserdetails: getUserDetails,
    verified: verified,
    setverified: setVerified,
    resetpassword: resetPassword,
    resetpasswordconfirm: resetPasswordConfirm,
    errormessage: errorMessage,
    seterrormessage: setErrorMessage,
  };

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
