import React from "react";
import fillZero from "../Functions/fillZero";

const GetTime = ({ date }) => {
  let d = new Date(date);
  let hours = d.getHours();
  let minutes = fillZero(d.getMinutes(), 2);

  return (
    <>
      {hours}:{minutes}
    </>
  );
};

export default GetTime;
