import React, { useState, useEffect } from "react";
import OrderCTWCounter from "./OrderCTWCounter";
import OrderCTWOptions from "./OrderCTWOptions";

const PCCutToWeightBody = ({ product, productoptions }) => {
  var [item, setItem] = useState();

  useEffect(() => {
    setItem({
      product: product.id,
      cartitemvat: product.vat,
      name: product.name,
      description: product.description,
      sku: product.sku,
    });
  }, [product]);

  const [selectOptions, setSelectOptions] = useState(false);

  return (
    <div>
      <h4 className="card-title">{product.name}</h4>
      {!selectOptions && (
        <>
          <p className="card-description ">{product.description}</p>
          <p className="card-text">
            Prijs{" "}
            <strong>
              {(product.price * (1 + product.vat)).toFixed(2)} €{product.per}
            </strong>
          </p>

          <OrderCTWCounter
            product={product}
            productoptions={productoptions}
            item={item}
            setItem={setItem}
            setToggler={setSelectOptions}
          />
        </>
      )}

      {selectOptions && productoptions && (
        <div>
          <OrderCTWOptions
            product={product}
            item={item}
            // setItem={setItem}
            productoptions={productoptions}
            setToggler={setSelectOptions}
          />
        </div>
      )}
    </div>
  );
};

export default PCCutToWeightBody;
