import React, { useContext, useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import AuthContext from "../Store/auth-context";

import GetCountry from "../Components/GetCountry";
import GetTitle from "../Components/GetTitle";
import getStatus from "../Functions/getStatus";
import useLayout from "../Functions/useLayout";

const OrderHistoryPage = () => {
  const authObject = useContext(AuthContext);

  const [orderHistory, setOrderHistory] = useState([]);
  const [viewing, setViewing] = useState(null);
  const [items, setItems] = useState([]);
  const [options, setOptions] = useState([]);

  var navigate = useNavigate();

  if (!authObject.accesstoken) {
    navigate("/");
  }

  const getOrderHistory = async () => {
    await fetch(process.env.REACT_APP_API_URL + "api/order/history/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        userid: authObject.user.id,
        webshop: authObject.webshoptoken,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => setOrderHistory(resp));
  };

  const getItems = async (order) => {
    var url = process.env.REACT_APP_API_URL + "api/order/history/items/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        webshop: authObject.webshoptoken,
        orderid: order.id,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setItems(resp);
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const getOptions = async (order) => {
    var url = process.env.REACT_APP_API_URL + "api/order/history/itemoptions/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        webshop: authObject.webshoptoken,
        orderid: order.id,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setOptions(resp);
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  useEffect(() => {
    getOrderHistory();
  }, []);

  useEffect(() => {
    if (viewing !== null) {
      getItems(viewing);
      getOptions(viewing);
    }
  }, [viewing]);

  return (
    <div
      className="ohpage py-3 px-1 mx-auto"
      style={{ backgroundColor: useLayout(11, 0), color: useLayout(11, 1) }}
    >
      {viewing === null && (
        <>
          <button className="btn btn-secondary" onClick={(e) => navigate("/")}>
            keer terug
          </button>
          <div className="h1 text-center mt-3">Uw bestellingen</div>
          <table className="table ohtable">
            <thead>
              <tr>
                <th scope="col">Order ref</th>
                <th scope="col">Order date</th>
                <th scope="col">Total Price</th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {orderHistory &&
                orderHistory.map((order, index) => {
                  return (
                    <tr key={index}>
                      <td>{order.ref}</td>
                      <td>{new Date(order.ordered).toDateString()}</td>
                      <td>{(order.totalprice + order.totalvat).toFixed(2)}</td>
                      <td>
                        <button
                          className="btn btn-secondary btn-sm"
                          onClick={(e) => {
                            setViewing(order);
                          }}
                        >
                          Bekijk
                        </button>
                      </td>
                      <td>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={()=>{}}
                    >
                      Opnieuw
                    </button>
                    {console.log('order:',order)}
                  </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </>
      )}
      {viewing !== null && (
        <>
          <button
            className="btn btn-secondary btn-small"
            onClick={(e) => {
              setViewing(null);
              setItems([]);
              setOptions([]);
            }}
          >
            Terug naar overzicht
          </button>
          <div className="row"><div className="h2 col-6 text-center mt-2">Bestelling</div><div className="col-6 justify-content-end"><button
                      className="btn btn-primary btn-sm mt-3"
                      onClick={()=>{}}
                    >
                      Opnieuw bestellen
                    </button></div></div>
          

          {/* ---------- Order Info ------------- */}
          <div>
            <div className="h3">Bestel details</div>
            <div className="row">
              <div className="col">Ref</div>
              <div className="col">{viewing.ref}</div>
            </div>
            <div className="row">
              <div className="col">Datum</div>
              <div className="col">{viewing.ordered.slice(0, 10)}</div>
            </div>

            <div className="row">
              <div className="col">Status</div>
              <div className="col">{getStatus(viewing)}</div>
            </div>

            {viewing.confirmation && (
              <>
                <div className="row">
                  <div className="col">Orderbevestiging</div>
                  <div className="col">
                    <a
                      href={viewing.confirmation}
                      target="_blank"
                      rel="noreferrer"
                      className="text-primary fst-italic"
                    >
                      bestand
                    </a>
                  </div>
                </div>
              </>
            )}
            {viewing.invoice && (
              <>
                <div className="row">
                  <div className="col">Factuur</div>
                  <div className="col">
                    <a
                      href={viewing.invoice}
                      target="_blank"
                      rel="noreferrer"
                      className="text-primary fst-italic"
                    >
                      bestand
                    </a>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* ---------- Invoice data ------------- */}
          <div>
            <div className="h3 mt-3">Factuur Address</div>
            <div className="row">
              <div className="col">Name</div>
              <div className="col">
                <GetTitle code={viewing.bill_title} /> {viewing.bill_firstname}{" "}
                {viewing.bill_name}
              </div>
            </div>
            <div className="row">
              <div className="col">Adres</div>
              <div className="col">
                {viewing.bill_street} {viewing.bill_hnr} {viewing.bill_hnrext}
              </div>
            </div>
            <div className="row">
              <div className="col">Stad</div>
              <div className="col">
                {viewing.bill_zip} {viewing.bill_city}
              </div>
            </div>
            <div className="row">
              <div className="col">Land</div>
              <div className="col">
                <GetCountry code={viewing.bill_country} />
              </div>
            </div>
          </div>

          {viewing.shipmethod === "M" && (
            <>
              {/* ---------- Shipping data ------------- */}
              <div>
                <div className="h3 mt-3">Verzend adres</div>
                <div className="row">
                  <div className="col">Naam</div>
                  <div className="col">
                    <GetTitle code={viewing.ship_title} />{" "}
                    {viewing.ship_firstname} {viewing.ship_name}
                  </div>
                </div>
                <div className="row">
                  <div className="col">Adres</div>
                  <div className="col">
                    {viewing.ship_street} {viewing.ship_hnr}{" "}
                    {viewing.ship_hnrext}
                  </div>
                </div>
                <div className="row">
                  <div className="col">Stad</div>
                  <div className="col">
                    {viewing.ship_zip} {viewing.ship_city}
                  </div>
                </div>
                <div className="row">
                  <div className="col">Land</div>
                  <div className="col">
                    <GetCountry code={viewing.ship_country} />
                  </div>
                </div>
              </div>
            </>
          )}

          {/* ---------- Ordered items ------------- */}
          <div>
            <div className="h3 mt-3">Bestelde items</div>
            <table className="table">
              <thead className="thead">
                <tr className="tr">
                  <th className="th">Product</th>
                  <th className="th">Prijs</th>
                  <th className="th"># units</th>
                  <th className="th">Totaal</th>
                </tr>
              </thead>
              <tbody className="tbody">
                {items &&
                  items.map((item, index) => {
                    return (
                      <Fragment key={index}>
                        {console.log('item:',item)}
                        <tr className="tr" key={index}>
                          <td className="td">{item.name}</td>
                          <td className="td">
                            {(
                              item.cartitemprice *
                              (1 + item.cartitemvat)
                            ).toFixed(2)}
                          </td>
                          <td className="td">
                            {item.weight === null ? (
                              <>{item.quantity}</>
                            ) : (
                              <>1x{item.weight}g</>
                            )}
                          </td>
                          <td className="td">
                            {(
                              item.cartitemtotal *
                              (1 + item.cartitemvat)
                            ).toFixed(2)}
                          </td>
                        </tr>
                        {item.options &&
                          options.length > 0 &&
                          item.options.length > 0 && (
                            <tr>
                              <td className="td"></td>
                              <td className="td">Options :</td>
                              <td className="td">
                                {item.options.map((option, index2) => {
                                  return (
                                    <Fragment key={index2}>
                                      {index2 > 0 && <>{", "}</>}
                                      {
                                        options.filter(
                                          (opt) => opt.id === option
                                        )[0].description
                                      }
                                    </Fragment>
                                  );
                                })}
                              </td>
                            </tr>
                          )}
                      </Fragment>
                    );
                  })}
                <tr className="tr">
                  <td className="td"></td>
                  <td className="td">
                    <strong>Totalprijs met BTW</strong>
                  </td>
                  <td className="td"></td>
                  <td className="td">
                    <strong>
                      {(viewing.totalprice + viewing.totalvat).toFixed(2)}
                    </strong>
                  </td>
                </tr>
                <tr className="tr">
                  <td className="td"></td>
                  <td className="td">BTW bedrag</td>
                  <td className="td"></td>
                  <td className="td">{viewing.totalvat.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderHistoryPage;
